import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

export default class Autosubmit extends Controller<HTMLFormElement> {
	public static override readonly targets = ['ignore', 'keep'];

	private declare readonly ignoreTargets: HTMLElement[];
	private declare readonly keepTargets: HTMLElement[];

	private readonly changeListener = (event: Event): void => {
		if (!(event.target instanceof HTMLElement)) {
			return;
		}
		if (this.ignoreTargets.includes(event.target)) {
			return;
		}
		if (
			event.target.matches(
				'input[type="radio"], input[type="checkbox"], select:focus, input[type="text"], textarea',
			)
		) {
			this.tryToSubmit();
		}
	};

	private readonly keyupListener = (event: KeyboardEvent): void => {
		if (!(event.target instanceof HTMLElement)) {
			return;
		}
		if (this.ignoreTargets.includes(event.target)) {
			return;
		}
		if (
			event.target.matches('input[type="text"]') &&
			event.code === 'Enter'
		) {
			this.tryToSubmit();
		}
	};

	public override connect(): void {
		this.element.addEventListener('change', this.changeListener);
		this.element.addEventListener('keyup', this.keyupListener);

		this.element.classList.add('autosubmit--attached');

		for (const submit of this.element.querySelectorAll<HTMLElement>(
			'[type="submit"]',
		)) {
			if (!this.keepTargets.includes(submit)) {
				submit.classList.add('autosubmit-submit--hidden');
			}
		}
	}

	public override disconnect(): void {
		for (const submit of this.element.querySelectorAll<HTMLElement>(
			'.autosubmit-submit--hidden',
		)) {
			submit.classList.remove('autosubmit-submit--hidden');
		}

		this.element.classList.remove('autosubmit--attached');

		this.element.removeEventListener('keyup', this.keyupListener);
		this.element.removeEventListener('change', this.changeListener);
	}

	private tryToSubmit(): void {
		Autosubmit.tryToSubmit(this.element);
	}

	// Do not even request to submit the form if any required control is
	// empty/unchecked; otherwise spurious errors/hints will appear.
	public static tryToSubmit(form: HTMLFormElement): void {
		for (const required of Array.from(
			form.querySelectorAll<HTMLElement>(':required'),
		)) {
			if (
				required.matches(`input[type="text"], input[type="file"],
							input[type="password"], textarea`)
			) {
				if (
					(required as HTMLInputElement | HTMLTextAreaElement)
						.value === ''
				) {
					return;
				}
			} else if (!required.matches(':checked')) {
				return;
			}
		}

		setTimeout(() => {
			form.requestSubmit();
		}, 5);
	}
}

application.register('autosubmit', Autosubmit);
