import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';
import MarkNav from '../../javascript/mark-nav';

application.register(
	'searchnavbar',
	class Searchnavbar extends Controller<HTMLElement> {
		public static override readonly targets = [
			'matches',
			'compact',
			'items',
		];

		private declare readonly matchesTarget: HTMLElement;
		private declare readonly hasMatchesTarget: boolean;
		private declare readonly compactTarget: HTMLElement;
		private declare readonly itemsTarget: HTMLElement;

		private markNav: MarkNav | null = null;

		public override connect(): void {
			window.addEventListener('cpc:prepare-page', () => {
				this.preparePage();
			});
			window.addEventListener('cpc:large-view:prepare-modal', () => {
				if (this.markNav != null) {
					this.markNav.prepareLargeView();
				}
			});
		}

		public preparePage(): void {
			if (this.hasMatchesTarget) {
				this.markNav = new MarkNav({
					matchSelector: 'mark',
					desktopNav: this.element,
					mobileNav: this.compactTarget,
					itemNav: this.itemsTarget,
					matchNav: this.matchesTarget,
				});
			}
		}

		public override disconnect(): void {
			this.markNav = null;
		}
	},
);
