import { Controller } from '@hotwired/stimulus';

import * as utils from './utils';
import application from './application';
import * as selections from './selections';
import * as tooltips from './tooltips';

export interface IDynamodalOptions {
	type: 'error' | 'notice' | 'custom';
	formData?: FormData;
	bodyClass?: string;
}

const DYNAMODAL_STRUCTURE = `
<aside class="modal">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title"></h1>
				<button
					type="button"
					class="close"
					aria-label="Close"
					data-dismiss="modal"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
			</div>
			<div class="modal-footer">
				<button
					type="button"
					class="btn btn-light"
					title="Close"
					data-dismiss="modal"
				>
					<span class="glyphicons glyphicons-remove d-inline d-sm-none" aria-hidden="true"></span>
					<span class="d-none d-sm-inline">Close</span>
				</button>
			</div>
		</div>
	</div>
</aside>
`;

export default class Dynamodal extends Controller {
	public static async spawn(
		source: string | HTMLElement,
		options: Partial<IDynamodalOptions> = {},
	): Promise<void> {
		if (typeof source !== 'string') {
			this.populate(source, options);
			return;
		}

		window.dispatchEvent(
			new CustomEvent('cpc:request-start', { detail: false }),
		);

		let response: Response;
		try {
			response = await fetch(source, {
				method: options.formData != null ? 'POST' : 'GET',
				body: options.formData,
			});
			if (!response.ok) {
				throw new Error(response.statusText);
			}
		} catch (error) {
			this.showLoadingError();
			throw error;
		} finally {
			window.dispatchEvent(
				new CustomEvent('cpc:request-end', { detail: false }),
			);
		}

		const documentString = await response.text();
		const parser = new DOMParser();
		const parsedDocument = parser.parseFromString(
			documentString,
			'text/html',
		);

		// Find the <main> and copy its attributes and children into an <aside>.
		const main = parsedDocument.querySelector<HTMLElement>('main');
		if (main != null) {
			const aside = parsedDocument.createElement('aside');
			for (const attr of main.attributes) {
				if (attr.nodeValue != null) {
					aside.setAttribute(attr.nodeName, attr.nodeValue);
				}
			}
			while (main.firstChild != null) {
				aside.appendChild(main.firstChild);
			}
			this.populate(aside, options);
		} else {
			this.populate(parsedDocument.documentElement, options);
		}
	}

	public static override readonly values = {
		selections: Boolean,
	};

	private declare selectionsValue: boolean;

	private readonly clickListener: EventListener = (event) => {
		this.onClick(event);
	};

	public override connect(): void {
		this.element.addEventListener('click', this.clickListener);
	}

	public override disconnect(): void {
		this.element.removeEventListener('click', this.clickListener);
	}

	private onClick(event: Event): void {
		const source = this.element.getAttribute('href');
		if (source == null) {
			return;
		}

		event.preventDefault();

		void Dynamodal.spawn(source, {
			formData: this.selectionsValue
				? utils.convertToFormData({ selections: selections.get() })
				: undefined,
		});
	}

	private static showLoadingError(): void {
		const p = document.createElement('p');
		p.textContent = 'The requested information could not be loaded.';
		this.populate(p, { type: 'error' });
	}

	private static populate(
		modalOrContent: HTMLElement,
		options: Partial<IDynamodalOptions>,
	): void {
		// Find the modal body. If not marked, add the modal structure.
		let modal = modalOrContent;
		let modalBody = modal.querySelector<HTMLElement>('.modal-body');
		if (modalBody == null) {
			const parser = document.createElement('div');
			parser.innerHTML = DYNAMODAL_STRUCTURE;
			modal = parser.querySelector<HTMLElement>('.modal')!;

			modalBody = modal.querySelector<HTMLElement>('.modal-body')!;
			modalBody.appendChild(modalOrContent);

			const modalTitle =
				modal.querySelector<HTMLElement>('.modal-title')!;
			switch (options.type) {
				case 'error':
					modalTitle.textContent = 'Error';
					break;
				case 'notice':
					modalTitle.textContent = 'Notice';
					break;
			}
		}

		// Set up the top-level modal itself.
		modal.tabIndex = -1;
		modal.setAttribute('role', 'dialog');
		modal.classList.add('modal');
		modal.classList.add('fade');

		// Set up the modal's document wrapper.
		const modalDialog = modal.querySelector<HTMLElement>('.modal-dialog');
		if (modalDialog != null) {
			modalDialog.setAttribute('role', 'document');
		}

		// Set up the modal's body.
		switch (options.type) {
			case 'error':
				modalBody.setAttribute('role', 'alert');
				modalBody.classList.add('alert-danger');
				modal.classList.add('error-modal');
				break;
			case 'notice':
				modalBody.setAttribute('role', 'alert');
				modalBody.classList.add('alert-warning');
				modal.classList.add('notice-modal');
				break;
		}
		if (options.bodyClass != null) {
			modalBody.classList.add(options.bodyClass);
		}

		// Set up any tooltips in the modal.
		tooltips.prepare(modal, { container: modal });

		// Hide the modal when navigating out of it, with appropriate exceptions.
		modal.addEventListener('click', (event): void => {
			if (
				!(event.target instanceof HTMLAnchorElement) ||
				event.target.hasAttribute('target') ||
				event.target.classList.contains('share-action') ||
				event.target.classList.contains('try-share-again') ||
				event.target.dataset['toggle'] === 'tab'
			) {
				return;
			}
			jQuery(modal).modal('hide');
		});

		modal.addJQEventListener('hidden.bs.modal', (): void => {
			modal.remove();
		});
		document.body.appendChild(modal);
		this.show(modal);
	}

	private static show(modal: HTMLElement): void {
		utils
			.makeAttempts(
				() => {
					if (!document.body.classList.contains('modal-open')) {
						jQuery(modal).modal('show');
						return true;
					}
					jQuery('.modal.show').modal('hide');
					return false;
				},
				40,
				25,
			)
			.catch((error: unknown) => {
				// eslint-disable-next-line no-console
				console.warn('Could not show modal dialog:', error);
			});
	}
}

application.register('dynamodal', Dynamodal);
