import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

import * as selections from '../../javascript/selections';

application.register(
	'selectionsbutton',
	class Selectionsbutton extends Controller {
		protected showSelections(event: Event): void {
			event.preventDefault();
			selections.show();
		}
	},
);
