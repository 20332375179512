import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

import * as tooltips from '../../javascript/tooltips';

application.register(
	'levelnodeactions',
	class Levelnodeactions extends Controller<HTMLElement> {
		public static override readonly targets = [
			'selector',
			'search',
			'share',
			'history',
		];

		private declare readonly searchTarget: HTMLAnchorElement;
		private declare readonly shareTarget: HTMLAnchorElement;
		private declare readonly historyTarget: HTMLAnchorElement;
		private declare readonly selectorTarget: HTMLInputElement;

		public override connect(): void {
			// HACK: Firefox has a weird caching (?) issue where checkboxes can
			// retain their checked status from one page to the next so we force
			// the checkbox to be unchecked when it's added to the page. It is
			// then checked if necessary during site.preparePage.
			this.selectorTarget.checked = false;

			const header = this.element.closest('header');
			if (header == null) {
				return;
			}

			const searchLink = header.dataset['search'];
			const shareLink = header.dataset['share'];
			const historyLink = header.parentElement?.dataset['history'];
			const nodeType = header.dataset['type'];

			if (searchLink != null) {
				this.searchTarget.setAttribute('href', searchLink);
			}
			if (shareLink != null) {
				this.shareTarget.setAttribute('href', shareLink);
			}

			const oldTitle = this.historyTarget.getAttribute('title');
			if (nodeType != null && oldTitle != null) {
				this.historyTarget.setAttribute(
					'title',
					oldTitle.replace(/nodeType/, nodeType),
				);
			}

			if (historyLink != null) {
				this.historyTarget.setAttribute('href', historyLink);
			} else {
				this.historyTarget.classList.add('disabled');
			}

			tooltips.prepare(this.element, { container: 'body' });
		}
	},
);
