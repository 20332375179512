import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';
import * as consent from '../../javascript/cookie-consent';

application.register(
	'settingsmodal',
	class Settingsmodal extends Controller {
		public static override readonly targets = ['footer', 'button', 'form'];

		private declare readonly footerTarget: HTMLElement;
		private declare readonly buttonTargets: HTMLElement[];
		private declare readonly formTarget: HTMLFormElement;

		public override connect(): void {
			if (this.element.classList.contains('modal')) {
				for (const button of this.buttonTargets) {
					this.footerTarget.appendChild(button);
				}
			}
		}

		public submit(): void {
			if (this.element.classList.contains('modal')) {
				this.formTarget.submit();
			}
		}

		public reset(): void {
			if (this.element.classList.contains('modal')) {
				const resetInput = document.createElement('input');
				resetInput.setAttribute('name', 'reset');
				resetInput.setAttribute('value', 'true');
				resetInput.setAttribute('type', 'hidden');

				this.formTarget.appendChild(resetInput);
				this.formTarget.submit();
			}
		}

		public openCookiePreferences(): void {
			consent.openDialog();
		}
	},
);
