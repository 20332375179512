import { debounce } from 'underscore';
import * as Cookies from 'js-cookie';

import * as utils from './utils';
import Dynamodal from './dynamodal';

type IPreviewTrigger = 'hover' | 'focus';

interface IPreviewContext {
	container: HTMLElement;
	trigger: IPreviewTrigger;
	timestamp: number;
	link: HTMLElement;
	type?: 'cite' | 'def' | 'fn' | 'tocItem';
	target?: URL;
	linkHovered: boolean;
	previewHovered: boolean;
}

let showForSections = false;
let showForDefinitions = false;

const containerTimestamps = new Map<HTMLElement, number>();
const preparedLinks = new Set<HTMLElement>();
const shownPreviews = new Map<HTMLElement, IPreviewContext>();

const previewLink = debounce(previewLinkImmediately, 200);

const LINK_SELECTOR =
	'a.cite, a.def[href], a.fn, .tocItem > a, .tocitem > a, .searchresultlist-result.node > a';
const MOBILE_MODAL_SELECTOR = 'a.fn, a.def[href]';

const CLOSE_BUTTON = `<button
	type="button"
	class="popover-close close"
	data-dismiss="popover"
	aria-label="Close"
	>
	<span aria-hidden="true">&times;</span>
</button>`;

window.addEventListener('cpc:prepare-page', preparePage);
window.addEventListener('cpc:nodes:navigate-on-page', reset);

function preparePage(): void {
	const optedOut = (Cookies.get('previews') ?? 'on') === 'off';
	showForSections =
		!optedOut && document.body.classList.contains('section_previews');
	showForDefinitions =
		!optedOut && document.body.classList.contains('definition_previews');

	if (showForSections || showForDefinitions) {
		document.body.addEventListener('click', onClickClose);
		document.body.addEventListener('keyup', onPressEscape);
		document.body.addEventListener('mouseover', onEnterLinkOrPreview);
		document.body.addEventListener('mouseout', onLeaveLinkOrPreview);
		document.body.addEventListener('focusout', onBlurLink);

		prepareScope('main.nodes, main.title-page, .searchresultlist');
		window.addEventListener('cpc:contents:prepare-modal', () => {
			prepareScope('#contents');
		});
		window.addEventListener('cpc:large-view:prepare-modal', () => {
			prepareScope('#large-view');
		});
	}
}

function prepareScope(selector: string): void {
	const container = document.querySelector<HTMLElement>(selector);
	if (container == null) {
		return;
	}

	container.addEventListener('click', onClickLink);

	container.addEventListener(
		'touchstart',
		(event: Event) => {
			if (!(event.target instanceof HTMLElement)) {
				return;
			}
			if (event.target.matches(MOBILE_MODAL_SELECTOR)) {
				event.target.classList.add('touched');
			}
		},
		{ passive: true },
	);

	const onEnter = (trigger: IPreviewTrigger, event: Event) => {
		if (!(event.target instanceof HTMLElement)) {
			return;
		}
		if (event.target.matches(LINK_SELECTOR)) {
			const timestamp = Date.now();
			containerTimestamps.set(container, timestamp);
			void previewLink({
				container,
				trigger,
				timestamp,
				link: event.target,
				linkHovered: false,
				previewHovered: false,
			});
		}
	};
	container.addEventListener('mouseover', onEnter.bind(undefined, 'hover'));
	container.addEventListener('focusin', onEnter.bind(undefined, 'focus'));

	container.addJQEventListener('shown.bs.popover', onShown);
}

function checkApplicability(ctx: IPreviewContext): boolean {
	return (
		// Only operate on the same navigation context that we started with.
		containerTimestamps.get(ctx.container) === ctx.timestamp &&
		// If the trigger no longer applies after the debounce delay, stop.
		ctx.link.matches(`:${ctx.trigger}`)
	);
}

function findLinkForPreview(preview: HTMLElement): HTMLElement | null {
	for (const link of preparedLinks) {
		if (link.getAttribute('aria-describedby') === preview.id) {
			return link;
		}
	}
	return null;
}

function findContextForPreview(preview: HTMLElement): IPreviewContext | null {
	for (const [link, ctx] of shownPreviews) {
		if (link.getAttribute('aria-describedby') === preview.id) {
			return ctx;
		}
	}
	return null;
}

async function previewLinkImmediately(ctx: IPreviewContext): Promise<void> {
	if (!checkApplicability(ctx)) {
		return;
	}

	// For links already being shown, do nothing.
	if (shownPreviews.has(ctx.link)) {
		return;
	}

	// For prepared links, jump directly to showing the preview.
	if (ctx.link.classList.contains('has-preview')) {
		showPreview(ctx);
		return;
	}

	evaluateLink(ctx);
	if (ctx.target == null) {
		return;
	}

	// Only operate on links of a supported type.
	const show = ctx.type === 'def' ? showForDefinitions : showForSections;
	if (!show) {
		return;
	}

	// If the link was activated by a touchscreen, use modal instead of popover,
	// but don't use either if the link's target and preview URL are distinct.
	const useModal = ctx.link.classList.contains('touched');
	ctx.target.searchParams.set('modal', useModal.toString());
	if (useModal && ctx.link.dataset['preview'] != null) {
		return;
	}

	if (useModal) {
		await Dynamodal.spawn(ctx.target.href, { bodyClass: 'main' });
		return;
	}

	try {
		const response = await fetch(ctx.target.href, { method: 'GET' });
		if (!response.ok) {
			throw new Error(response.statusText);
		}

		if (response.status === 204) {
			return;
		} // 204 No Content

		createPreview(ctx, await response.text());
	} catch (error) {
		// ignore; best effort
	}
}

function evaluateLink(ctx: IPreviewContext): void {
	// Get the link target, or the preview URL if it is distinct.
	const linkTarget =
		ctx.link.dataset['preview'] ?? ctx.link.getAttribute('href');
	if (linkTarget == null) {
		return;
	}

	ctx.target = new URL(linkTarget, location.href);
	ctx.type = 'cite';

	// These links shouldn't be external, but ignore them if they are.
	if (
		ctx.target.hostname !== '' &&
		ctx.target.hostname !== location.hostname
	) {
		return;
	}

	// Links to products (via "." nodes) can't be previewed either.
	if (ctx.target.pathname.endsWith('/')) {
		return;
	}

	// Change any anchor into a query param.
	if (ctx.target.hash !== '') {
		ctx.target.searchParams.set('anchor', ctx.target.hash.slice(1));
		ctx.target.hash = '';
	}

	// Adjust the link to break out of any comparison.
	if (/\/compare\b/.test(ctx.target.pathname)) {
		ctx.target.pathname = ctx.target.pathname.replace('/compare', '');
	} else if (
		!ctx.target.pathname.startsWith('/') &&
		location.href.includes('/compare')
	) {
		ctx.target.pathname = `../${ctx.target.pathname}`;
	}

	// Add the preview portion of the path.
	ctx.target.pathname += '/preview';

	// Determine the link type.
	if (ctx.link.classList.contains('def')) {
		ctx.type = 'def';
	} else if (ctx.link.classList.contains('fn')) {
		ctx.type = 'fn';
	} else if (
		ctx.link.parentElement != null &&
		ctx.link.parentElement.matches(
			'.tocItem, .tocitem, .searchresultlist-result',
		)
	) {
		ctx.type = 'tocItem';
	}
	ctx.target.searchParams.set('type', ctx.type);
}

function createPreview(ctx: IPreviewContext, content: string): void {
	const parser = new DOMParser();
	const parsedContent = parser.parseFromString(content, 'text/html');
	const contentMain = parsedContent.querySelector<HTMLElement>('main');
	if (contentMain == null) {
		return;
	}

	// Create a generic container <div> for the content and add a Close button.
	const contentDiv = document.createElement('div');
	contentDiv.innerHTML = CLOSE_BUTTON;

	// Relocate contentMain's children into the container <div>, since a <main>
	// would be incorrect in this context.
	while (contentMain.firstChild != null) {
		contentDiv.appendChild(contentMain.firstChild);
	}

	const elided = contentMain.classList.contains('elided');

	ctx.link.classList.add('has-preview');
	preparedLinks.add(ctx.link);

	jQuery(ctx.link).popover({
		container: 'body',
		boundary: 'viewport',
		content: contentDiv,
		html: true,
		placement: ctx.type === 'tocItem' ? 'left' : 'right',
		template: `<div class="popover preview" role="tooltip">
				<div class="arrow no-custom-style"></div>
				<div class="popover-body main${elided ? ' elided' : ''}">
				</div>
			</div>`,
		trigger: 'manual',
	});

	showPreview(ctx);
}

function showPreview(ctx: IPreviewContext): void {
	if (!checkApplicability(ctx)) {
		return;
	}
	ctx.linkHovered = ctx.link.matches('hover');
	jQuery(ctx.link).popover('show');
	shownPreviews.set(ctx.link, ctx);
}

function hidePreview(link: HTMLElement): void {
	jQuery(link).popover('hide');
	shownPreviews.delete(link);
}

function hideAllPreviews(): void {
	for (const link of preparedLinks) {
		hidePreview(link);
	}
}

function onShown(): void {
	for (const pop of utils.findElements(document, '.popover-body')) {
		pop.classList.toggle('overflown', pop.offsetHeight < pop.scrollHeight);
	}
}

function onClickLink(event: Event): void {
	if (!(event.target instanceof HTMLElement)) {
		return;
	}

	if (!event.target.matches(LINK_SELECTOR)) {
		return;
	}

	if (event.target.classList.contains('touched')) {
		// The modal will be displayed instead.
		event.stopPropagation();
		event.preventDefault();
	} else {
		// Hide the preview during navigation.
		hidePreview(event.target);
	}
}

function onEnterLinkOrPreview(event: Event): void {
	if (!(event.target instanceof HTMLElement)) {
		return;
	}

	const preview = event.target.closest<HTMLElement>('.popover');
	if (preview != null) {
		const ctx = findContextForPreview(preview);
		if (ctx != null) {
			ctx.previewHovered = true;
		}
		return;
	}

	const ctx = shownPreviews.get(event.target);
	if (ctx != null) {
		ctx.linkHovered = true;
	}
}

function onLeaveLinkOrPreview(event: Event): void {
	if (!(event.target instanceof HTMLElement)) {
		return;
	}

	const preview = event.target.closest<HTMLElement>('.popover');
	if (preview != null) {
		const ctx = findContextForPreview(preview);
		if (ctx != null) {
			ctx.previewHovered = false;
			maybeHidePreview(ctx);
		}
		return;
	}

	const ctx = shownPreviews.get(event.target);
	if (ctx != null) {
		ctx.linkHovered = false;
		maybeHidePreview(ctx);
	}
}

const maybeHidePreview = debounce(maybeHidePreviewImmediately, 100);

function maybeHidePreviewImmediately(ctx: IPreviewContext): void {
	if (ctx.trigger === 'hover' && !ctx.linkHovered && !ctx.previewHovered) {
		hidePreview(ctx.link);
	}
}

function onBlurLink(event: Event): void {
	if (!(event.target instanceof HTMLElement)) {
		return;
	}

	const ctx = shownPreviews.get(event.target);
	if (ctx?.trigger === 'focus') {
		hidePreview(event.target);
	}
}

function onClickClose(event: Event): void {
	if (!(event.target instanceof HTMLElement)) {
		return;
	}

	if (!event.target.classList.contains('popover-close')) {
		return;
	}

	const preview = event.target.closest<HTMLElement>('.popover');
	if (preview != null) {
		const link = findLinkForPreview(preview);
		if (link != null) {
			hidePreview(link);
		}
	}
}

function onPressEscape(event: KeyboardEvent): void {
	if (event.code === 'Escape') {
		hideAllPreviews();
	}
}

function reset(): void {
	hideAllPreviews();
	containerTimestamps.clear();
}
