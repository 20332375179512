/// <reference types="gtag.js" />

import * as consent from './cookie-consent';

declare global {
	interface Window {
		googleParams?: {
			prefix: string;
			tagId: string;
		};
	}
}

window.addEventListener('cpc:prepare-page', preparePage);
document.addEventListener('submit', onFormSubmit);

function preparePage(): void {
	if (window.googleParams == null) {
		return;
	}

	// Set default consent and handle updates.
	setConsent('default');
	window.addEventListener('cpc:consent:update', () => {
		setConsent('update');
	});

	// Configure overall.
	gtag('js', new Date());
	gtag('set', {
		dimension0: window.googleParams.prefix,
		allow_google_signals: false,
		allow_ad_personalization_signals: false,
	});

	// Configure the GA4 tag.
	gtag('config', window.googleParams.tagId, {
		cookie_flags: 'SameSite=None;Secure',
		send_page_view: true,
	});
}

function setConsent(type: Gtag.ConsentArg): void {
	gtag('consent', type, {
		ad_personalization: 'denied',
		ad_user_data: 'denied',
		ad_storage: 'denied',
		analytics_storage: consent.isCategoryAllowed('analytics')
			? 'granted'
			: 'denied',
		functionality_storage: consent.isCategoryAllowed('customization')
			? 'granted'
			: 'denied',
		personalization_storage: 'denied',
		security_storage: 'granted',
	});
}

export function recordShareEvent(
	method: string,
	content_type: string,
	item_id: string,
): void {
	gtag('event', 'share', { method, content_type, item_id });
}

function onFormSubmit(event: SubmitEvent): void {
	const form = event.target;
	if (!(form instanceof HTMLFormElement)) {
		return;
	}

	const q = form.querySelector<HTMLInputElement>('input[name="q"]');
	if (q == null) {
		return;
	}

	let origin: string;
	if (form.closest('.searchresultlist') != null) {
		origin = 'results-page';
	} else if (form.closest('main.home') != null) {
		origin = 'home-page';
	} else if (form.closest('.contents') != null) {
		origin = 'contents';
	} else if (form.closest('.sitenavbar') != null) {
		origin = 'navbar';
	} else {
		return;
	}

	gtag('event', 'search', { search_term: q.value, origin });
}
