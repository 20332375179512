import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

application.register(
	'siteprogress',
	class Siteprogress extends Controller {
		public static readonly classes = ['active'];

		private declare readonly activeClass: string;

		private requestsActive = 0;

		private readonly requestStartListener = this.onRequestStart.bind(this);
		private readonly requestEndListener = this.onRequestEnd.bind(this);

		public override connect(): void {
			window.addEventListener(
				'cpc:request-start',
				this.requestStartListener,
			);
			window.addEventListener('cpc:request-end', this.requestEndListener);
		}

		public override disconnect(): void {
			window.removeEventListener(
				'cpc:request-end',
				this.requestEndListener,
			);
			window.removeEventListener(
				'cpc:request-start',
				this.requestStartListener,
			);
		}

		private onRequestStart(): void {
			++this.requestsActive;

			setTimeout(() => {
				this.updateProgress();
			}, 250);
		}

		private onRequestEnd(): void {
			--this.requestsActive;

			this.updateProgress();
		}

		private updateProgress(): void {
			this.element.classList.toggle(
				this.activeClass,
				this.requestsActive > 0,
			);
		}
	},
);
