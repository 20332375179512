import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

application.register(
	'homepage',
	class Homepage extends Controller {
		public static override readonly targets = ['searchInput'];

		private declare readonly searchInputTarget: HTMLInputElement;

		public override connect(): void {
			if (window.matchMedia('(min-width: 768px)').matches) {
				this.searchInputTarget.focus();
			}
		}
	},
);
