import { debounce, throttle } from 'underscore';
import jQuery from 'jquery';
import * as Cookies from 'js-cookie';
import RailsUJS from '@rails/ujs';

import * as utils from './utils';
import * as tooltips from './tooltips';

let prepared = false;
let topStuckOffset = 0;

(window as unknown as Record<string, unknown>)['jQuery'] = jQuery;

if (checkImmediate()) {
	if (document.readyState === 'complete') {
		preparePage();
	} else {
		document.addEventListener('DOMContentLoaded', preparePage);
	}

	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	window.addEventListener('load', renderPage);

	window.addEventListener('resize', debounce(onResize, 25), {
		passive: true,
	});
	window.addEventListener('scroll', throttle(onScroll, 25), {
		passive: true,
	});
}

export function getTopStuckOffset(): number {
	return topStuckOffset;
}

// Check immediately for conditions that may require redirect or reload.

function checkImmediate(): boolean {
	let reloadNow = false;
	let redirectTo: string | null = null;

	// Check for a hashbang from an incomplete modernizer redirect.

	redirectTo ??= checkModernizingHashbang();

	// Check viewport size and, if needed, reload to apply different chunking.

	if (updateViewportCookie()) {
		reloadNow = true;
	}

	// Break out of frames, redirect and/or reload as required.

	if (self !== top && top != null) {
		top.location.href = redirectTo ?? location.href;
		return false;
	} else if (redirectTo != null) {
		location.href = redirectTo;
		return false;
	} else if (reloadNow) {
		location.reload();
		return false;
	} else {
		return true;
	}
}

function checkModernizingHashbang(): string | null {
	const hashbang = /^#!(.+)/.exec(location.hash);
	if (hashbang == null || location.pathname.includes('modernize')) {
		return null;
	}

	let legacyPath = hashbang[1]!;
	let innerHash = /^(\/\w+)\.html(?:#|%23)(.+)$/.exec(legacyPath);
	if (innerHash != null) {
		// older CPC Legacy title-level HTML file
		legacyPath =
			innerHash[1] === `/${innerHash[2]!}`
				? `${innerHash[1]}.html`
				: `${innerHash[1]!}/${innerHash[2]!}.html`;
	} else {
		innerHash = /^(.*\/)[^/]+\.html(?:#|%23)(.+)$/.exec(legacyPath);
		if (innerHash != null) {
			// newer CPC Legacy chapter-level HTML file
			legacyPath = `${innerHash[1]!.replace(
				/^(\/\w+\d{2}[A-Z]?\b)+/,
				'',
			)}${innerHash[2]!}.html`;
		}
	}

	return `/modernize${legacyPath}`;
}

// Prepare the page for display and use.

function preparePage(): void {
	if (prepared) {
		return;
	}
	prepared = true;

	// Set up Rails UJS.
	RailsUJS.start();

	// Set up Bootstrap.
	const tooltipAllowList = (
		jQuery.fn.tooltip as unknown as JQueryTooltipFunction
	).Constructor.Default.whiteList;
	tooltipAllowList['article'] = [];
	tooltipAllowList['section'] = [];
	tooltipAllowList['header'] = [];
	tooltipAllowList['footer'] = [];

	// Check animation cookie to enable or disable jQuery animations.
	const animationCookie = Cookies.get('animation');
	jQuery.fx.off = animationCookie === 'none' || animationCookie === 'light';

	// Set up specific animations.
	jQuery.easing['swingIn'] = (p) => jQuery.easing['swing']!(p / 2);
	jQuery.easing['swingOut'] = (p) => jQuery.easing['swing']!(0.5 + p / 2);

	// Update <body> classes used for styling.
	document.body.classList.add('js');
	if (utils.isIE()) {
		// Keep this for showing the IE warning.
		document.body.classList.add('ie');
	}

	// Prepare the navigation bar(s).
	const navbars = document.getElementById('navbars');
	onResize();
	if (navbars != null) {
		tooltips.prepare(navbars, { container: navbars });
	}

	// Allow other modules to prepare the page.
	window.dispatchEvent(new Event('cpc:prepare-page'));
}

// Set up features that require the page to be fully loaded.

async function renderPage(): Promise<void> {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (document.fonts != null) {
		await document.fonts.ready;
	}

	window.dispatchEvent(new Event('cpc:render-page'));
}

function onResize(): void {
	const navbars = document.getElementById('navbars');
	topStuckOffset = navbars?.offsetHeight ?? 0;

	// Update the viewport cookie if needed, but don't immediately reload to
	// apply a different chunking, because that would be annoying.
	updateViewportCookie();
}

function onScroll(): void {
	const navbars = document.getElementById('navbars');
	const siteHeader = document.getElementById('site-header');
	if (navbars != null && siteHeader != null) {
		const navbarsStuck =
			getComputedStyle(navbars).position === 'fixed' ||
			window.scrollY >= siteHeader.offsetHeight;
		navbars.classList.toggle('stuck', navbarsStuck);
	}
}

// Update the cookie indicating the width of the viewport, if needed. Returns
// whether any update might require a reload to apply a different chunking.

function updateViewportCookie(): boolean {
	const oldValue = Cookies.get('viewport');
	const newValue = window.matchMedia('(min-width: 768px)').matches
		? 'large'
		: 'small';
	if (oldValue === newValue) {
		return false;
	}

	Cookies.set('viewport', newValue, {
		expires: 30,
		secure: true,
		sameSite: 'strict',
	});
	const autoChunking = (Cookies.get('chunking') ?? 'auto') === 'auto';
	return oldValue != null || (newValue === 'large' && autoChunking);
}
