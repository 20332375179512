import { Controller } from '@hotwired/stimulus';
import { debounce } from 'underscore';

import application from '../../javascript/application';

import MarkNav from '../../javascript/mark-nav';
import * as nodes from '../../javascript/nodes';
import * as tooltips from '../../javascript/tooltips';

application.register(
	'comparingnavbar',
	class Comparingnavbar extends Controller<HTMLElement> {
		public static override readonly targets = [
			'link',
			'versions',
			'differences',
			'compact',
		];

		private declare readonly linkTargets: HTMLAnchorElement[];
		private declare readonly versionsTargets: HTMLAnchorElement[];
		private declare readonly differencesTarget: HTMLElement;
		private declare readonly compactTarget: HTMLElement;

		private comparingMarkNav: MarkNav | null = null;
		private readonly updateListener = debounce(() => {
			this.update();
		}, 250);

		public override connect(): void {
			window.addEventListener(
				'cpc:nodes:change-current',
				this.updateListener,
			);
			window.addEventListener('cpc:large-view:prepare-modal', () => {
				if (this.comparingMarkNav != null) {
					this.comparingMarkNav.prepareLargeView();
				}
			});

			tooltips.prepare(this.element, {
				placement: 'top',
				container: '#navbars',
				destroyExisting: true,
			});

			this.comparingMarkNav = new MarkNav({
				matchSelector: 'ins:not(:empty), del:not(:empty)',
				desktopNav: this.element,
				mobileNav: this.compactTarget,
				matchNav: this.differencesTarget,
			});
		}

		public override disconnect(): void {
			window.removeEventListener(
				'cpc:nodes:change-current',
				this.updateListener,
			);
			this.comparingMarkNav = null;
		}

		public update(): void {
			if (
				this.linkTargets[0] == null ||
				this.versionsTargets[0] == null
			) {
				return;
			}

			const currentNode = nodes.getCurrent();
			const currentID = currentNode?.id ?? '';

			for (const link of this.linkTargets) {
				link.href = link.href.replace(
					/\/[^/?]+(\?.+)?$/,
					`/${currentID}$1`,
				);
				link.classList.toggle('disabled', currentID === '');
			}

			for (const link of this.versionsTargets) {
				link.href = link.href.replace(
					/\bnode=[^&]+/,
					`node=${currentID}`,
				);
				link.classList.toggle('disabled', currentID === '');
			}
		}
	},
);
