import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

application.register(
	'appearancesettings',
	class Appearancesettings extends Controller {
		public static override readonly targets = [
			'previewHeader',
			'previewBody',
			'fontFamily',
			'textSizeOptions',
			'sizeDown',
			'sizeUp',
			'lineSpacing',
			'colorTheme',
			'defaultFont',
			'primaryColor',
			'mainColor',
		];

		private declare readonly previewHeaderTarget: HTMLElement;
		private declare readonly previewBodyTarget: HTMLElement;

		private declare readonly fontFamilyTargets: HTMLOptionElement[];
		private declare readonly textSizeOptionsTargets: HTMLOptionElement[];
		private declare readonly sizeDownTarget: HTMLSelectElement;
		private declare readonly sizeUpTarget: HTMLSelectElement;
		private declare readonly lineSpacingTargets: HTMLInputElement[];
		private declare readonly colorThemeTargets: HTMLOptionElement[];

		private declare readonly defaultFontTarget: HTMLElement;
		private declare readonly primaryColorTarget: HTMLElement;
		private declare readonly mainColorTarget: HTMLElement;

		public override connect(): void {
			this.element.classList.add('appearancesettings--attached');

			this.updateTextSizeControls();
			this.updatePreview();
		}

		protected updatePreview(): void {
			const fontFamily = this.fontFamilyTargets.find(
				(font) => font.selected,
			);
			const fontFamilyValue =
				fontFamily != null && fontFamily.value !== ''
					? fontFamily.innerText
					: // For the default font, get the interface's font.
						window
							.getComputedStyle(this.defaultFontTarget)
							.getPropertyValue('font-family');
			this.previewHeaderTarget.style.fontFamily = fontFamilyValue;
			this.previewBodyTarget.style.fontFamily = fontFamilyValue;

			const textSize = this.textSizeOptionsTargets.find(
				(size) => size.selected,
			)?.dataset['textSize'];
			const fontSize =
				textSize != null
					? `calc(var(--default-font-size, 16px) * ${textSize})`
					: 'inherit';
			this.previewHeaderTarget.style.fontSize = fontSize;
			this.previewBodyTarget.style.fontSize = fontSize;

			const lineHeight =
				this.lineSpacingTargets.find((spacing) => spacing.checked)
					?.dataset['lineSpacing'] ?? 'inherit';
			this.previewHeaderTarget.style.lineHeight = lineHeight;
			this.previewBodyTarget.style.lineHeight = lineHeight;

			const colorTheme = this.colorThemeTargets.find(
				(theme) => theme.selected,
			);
			if (colorTheme != null && colorTheme.value !== '') {
				const foreground =
					colorTheme.dataset['foreground'] ?? 'inherit';
				const background =
					colorTheme.dataset['background'] ?? 'inherit';
				this.previewHeaderTarget.style.background = background;
				this.previewHeaderTarget.style.color = foreground;
				this.previewBodyTarget.style.background = background;
				this.previewBodyTarget.style.color = foreground;
			} else {
				// For the default theme, get this interface's colors.
				const mainColors = window.getComputedStyle(
					this.mainColorTarget,
				);
				const primaryColors = window.getComputedStyle(
					this.primaryColorTarget,
				);
				this.previewHeaderTarget.style.background =
					primaryColors.getPropertyValue('background-color');
				this.previewHeaderTarget.style.color =
					primaryColors.getPropertyValue('color');
				this.previewBodyTarget.style.background =
					mainColors.getPropertyValue('background-color');
				this.previewBodyTarget.style.color =
					mainColors.getPropertyValue('color');
			}
		}

		protected setLineSpacing(event: Event): void {
			if (!(event.target instanceof HTMLElement)) {
				return;
			}
			const lineSpacing = event.target.dataset['lineSpacing'];
			const lineSpacingInput = this.lineSpacingTargets.find(
				(spacing) => spacing.dataset['lineSpacing'] === lineSpacing,
			);
			if (lineSpacingInput != null) {
				lineSpacingInput.checked = true;
			}
			this.updatePreview();
		}

		private incrementTextSize(up: boolean): void {
			const oldSelected = this.textSizeOptionsTargets.find(
				(option) => option.selected,
			);
			if (oldSelected == null) {
				return;
			}
			const newIndex =
				this.textSizeOptionsTargets.indexOf(oldSelected) +
				(up ? 1 : -1);
			const newSelected = this.textSizeOptionsTargets[newIndex];
			if (newSelected != null) {
				newSelected.selected = true;
				this.updateTextSizeControls();
				this.updatePreview();
			}
		}

		protected textSizeDown(event: Event) {
			event.preventDefault();
			this.incrementTextSize(false);
		}

		protected textSizeUp(event: Event) {
			event.preventDefault();
			this.incrementTextSize(true);
		}

		private updateTextSizeControls(): void {
			const available = this.textSizeOptionsTargets.filter(
				(option) => !option.disabled,
			);
			const selected = this.textSizeOptionsTargets.find(
				(option) => option.selected,
			);
			this.sizeDownTarget.disabled = selected === available[0];
			this.sizeUpTarget.disabled = selected === available.at(-1);
		}
	},
);
