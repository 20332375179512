import { Controller } from '@hotwired/stimulus';
import { debounce } from 'underscore';

import application from '../../javascript/application';
import * as nodes from '../../javascript/nodes';

application.register(
	'historicnavbar',
	class Historicnavbar extends Controller {
		public static override readonly targets = ['link', 'versions'];

		private declare readonly linkTargets: HTMLAnchorElement[];
		private declare readonly versionsTargets: HTMLAnchorElement[];

		private readonly updateListener = debounce(() => {
			this.update();
		}, 250);

		public override connect(): void {
			window.addEventListener(
				'cpc:nodes:change-current',
				this.updateListener,
			);
		}

		public override disconnect(): void {
			window.removeEventListener(
				'cpc:nodes:change-current',
				this.updateListener,
			);
		}

		public update(): void {
			if (
				this.linkTargets[0] == null ||
				this.versionsTargets[0] == null
			) {
				return;
			}

			const currentNode = nodes.getCurrent();
			const currentID = currentNode?.id ?? '';

			for (const link of this.linkTargets) {
				link.href = link.href.replace(
					/\/[^/?]+(\?.+)?$/,
					`/${currentID}$1`,
				);
				link.classList.toggle('disabled', currentID === '');
			}

			for (const link of this.versionsTargets) {
				link.href = link.href.replace(
					/\bnode=[^&]+/,
					`node=${currentID}`,
				);
				link.classList.toggle('disabled', currentID === '');
			}
		}
	},
);
