import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

import * as utils from '../../javascript/utils';
import * as scroll from '../../javascript/scroll';
import * as selections from '../../javascript/selections';
import * as tooltips from '../../javascript/tooltips';

application.register(
	'contents',
	class Contents extends Controller<HTMLElement> {
		public static override readonly targets = [
			'navTab',
			'productTab',
			'tocControls',
			'selectControls',
			'modalBody',
			'tabPane',
			'currentTabPane',
			'tocItem',
			'currentTocItem',
		];

		private declare readonly navTabTargets: HTMLElement[];
		private declare readonly productTabTargets: HTMLElement[];
		private declare readonly tocControlsTarget: HTMLElement;
		private declare readonly selectControlsTarget: HTMLElement;
		private declare readonly modalBodyTarget: HTMLElement;

		private declare readonly tabPaneTargets: HTMLElement[];
		private declare readonly currentTabPaneTarget: HTMLElement;
		private declare readonly hasCurrentTabPaneTarget: boolean;

		private declare readonly tocItemTargets: HTMLElement[];
		private declare readonly currentTocItemTarget: HTMLElement;
		private declare readonly hasCurrentTocItemTarget: boolean;

		private revealedCurrentNode = false;
		private sidebarDisplayed = false;

		public override connect(): void {
			window.addEventListener('cpc:prepare-page', () => {
				this.preparePage();
			});
		}

		protected expandAll(): void {
			this.element.classList.add('contents--complete');
			for (const tocItem of this.tocItemTargets) {
				const pane = tocItem.closest('.tab-pane');
				if (pane == null || !pane.classList.contains('active')) {
					continue;
				}
				if (tocItem.classList.contains('tocitem--branch')) {
					tocItem.dispatchEvent(new Event('cpc:contents:expand-all'));
				}
			}
		}

		protected collapseAll(): void {
			for (const tocItem of this.tocItemTargets) {
				const pane = tocItem.closest('.tab-pane');
				if (pane == null || !pane.classList.contains('active')) {
					continue;
				}
				if (tocItem.classList.contains('tocitem--branch')) {
					tocItem.dispatchEvent(
						new Event('cpc:contents:collapse-all'),
					);
				}
			}
		}

		protected clearSelections(): void {
			selections.clear();
		}

		protected shareSelections(): void {
			if (!this.sidebarDisplayed) {
				jQuery(this.element).modal('hide');
			}
		}

		private preparePage(): void {
			this.sidebarDisplayed =
				this.element.classList.contains('contents-sidebar');

			this.element.addJQEventListener('show.bs.modal', () => {
				this.onShow();
			});
			this.element.addJQEventListener('shown.bs.modal', () => {
				this.revealCurrentNode();
			});

			this.element.addJQEventListener('show.bs.tab', (e): void => {
				void this.onShowTab(e);
			});
			this.element.addJQEventListener('shown.bs.tab', (): void => {
				if (!this.sidebarDisplayed) {
					jQuery(this.element).modal('show');
				} // in case revealed for specific tab
			});

			tooltips.prepare(this.element, { container: this.element });

			if (this.sidebarDisplayed) {
				const activePane =
					this.element.querySelector<HTMLElement>('.tab-pane.active');
				if (activePane != null && this.tocItemTargets.length === 0) {
					void this.prepareTabPane(activePane);
				}
			}

			window.dispatchEvent(new Event('cpc:contents:prepare-modal'));
		}

		protected switchProduct(event: Event): void {
			const productLink = event.target;
			if (!(productLink instanceof HTMLAnchorElement)) {
				return;
			}
			event.preventDefault();

			const href = productLink.getAttribute('href');
			if (href == null) {
				return;
			}
			const productTabLink = this.element.querySelector(
				`#contents .nav-link[href="${CSS.escape(href)}"]`,
			);
			if (productTabLink == null) {
				return;
			}

			for (const tab of this.productTabTargets) {
				tab.classList.toggle(
					'current',
					tab === productTabLink.parentNode,
				);
			}

			jQuery(productTabLink).tab('show');
		}

		private onShow(): void {
			this.revealedCurrentNode = false;

			const activePane =
				this.element.querySelector<HTMLElement>('.tab-pane.active');
			if (activePane != null) {
				void this.prepareTabPane(activePane);
			}
		}

		private async onShowTab(event: utils.JQEvent): Promise<void> {
			if (!(event.target instanceof HTMLElement)) {
				return;
			}

			// We assume that the href will be an anchor on this page.
			const tabAnchor = event.target.getAttribute('href');
			if (tabAnchor == null) {
				return;
			}

			const tabPane = document.querySelector<HTMLElement>(tabAnchor);
			if (tabPane == null) {
				return;
			}

			// HACK: tab panes are failing to show/hide on tab click, so do it manually
			for (const tab of this.tabPaneTargets) {
				tab.classList.remove('active');
			}

			await this.prepareTabPane(tabPane);
			jQuery(tabPane).tab('show');
		}

		private async prepareTabPane(tabPane: HTMLElement): Promise<void> {
			const scope = tabPane.dataset['scope'];
			const source = tabPane.dataset['source'];

			if (tabPane.classList.contains('loading')) {
				return;
			}

			this.tocControlsTarget.classList.toggle(
				'd-sm-none',
				scope === 'all' || scope === 'selections',
			);
			this.selectControlsTarget.classList.toggle(
				'd-none',
				scope !== 'selections',
			);

			if (tabPane.classList.contains('loaded')) {
				if (scope === 'selections') {
					tabPane.classList.remove('loaded');
				} else {
					return;
				}
			}
			if (source == null) {
				return;
			}

			tabPane.classList.add('loading');
			window.dispatchEvent(
				new CustomEvent('cpc:request-start', { detail: false }),
			);

			let response: Response;
			try {
				response = await fetch(source, {
					method: scope === 'selections' ? 'POST' : 'GET',
					body:
						scope === 'selections'
							? utils.convertToFormData({
									selections: selections.get(),
									interactive: true,
								})
							: undefined,
				});
				if (!response.ok) {
					throw new Error(response.statusText);
				}
			} finally {
				tabPane.classList.remove('loading');
				window.dispatchEvent(
					new CustomEvent('cpc:request-end', { detail: false }),
				);
			}

			const parser = new DOMParser();
			const parsedContent = parser.parseFromString(
				await response.text(),
				'text/html',
			);
			const contentMain =
				parsedContent.querySelector<HTMLElement>('main');

			if (contentMain == null) {
				return;
			}

			tabPane.classList.add('loaded');

			while (tabPane.firstChild != null) {
				tabPane.firstChild.remove();
			}

			for (const el of utils.findElements(
				contentMain,
				'.contents-toc, .alert',
			)) {
				tabPane.appendChild(el);
			}

			if (scope === 'selections') {
				return;
			}
			selections.updateAll();

			this.revealCurrentNode();
		}

		private revealCurrentNode(): void {
			if (
				this.revealedCurrentNode ||
				!this.hasCurrentTabPaneTarget ||
				!this.hasCurrentTocItemTarget
			) {
				return;
			}

			if (
				utils.matchingChild(this.currentTabPaneTarget, '.alert') == null
			) {
				const children = utils.matchingChildren(
					this.currentTocItemTarget,
					'ul',
				);
				const childrenHeight = children[0]?.offsetHeight ?? 0;
				scroll.intoView(this.currentTocItemTarget, {
					container: this.modalBodyTarget,
					minMargin:
						((this.currentTocItemTarget.offsetHeight -
							childrenHeight) /
							2) *
						-1,
				});
			}

			this.revealedCurrentNode = true;
		}
	},
);
