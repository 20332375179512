import { throttle, debounce } from 'underscore';

import * as utils from './utils';
import * as scroll from './scroll';
import * as site from './site';

let levelNodes: HTMLElement[] = [];
let subsecNodes: HTMLElement[] = [];
let chunkNode: HTMLElement | null = null;
let anchorNode: HTMLElement | null = null;
let exactAnchorNode: HTMLElement | null = null;

let visibleNodes: HTMLElement[] = [];
let currentNode: HTMLElement | null = null;
let updating = false;
let stuckHeight: number | undefined;

window.addEventListener('cpc:prepare-page', preparePage);

export function getLevelNodes(): HTMLElement[] {
	return levelNodes;
}

export function getSubsecNodes(): HTMLElement[] {
	return subsecNodes;
}

export function getExactAnchor(): HTMLElement | null {
	return exactAnchorNode;
}

export function getCurrent(): HTMLElement | null {
	return currentNode;
}

export function getChunk(): HTMLElement | null {
	return chunkNode;
}

export function getByID(nodeID: string): HTMLElement | null {
	return (
		levelNodes.find((node) => node.id === nodeID) ??
		subsecNodes.find((node) => node.id === nodeID) ??
		null
	);
}

export function getStuckHeight(): number | undefined {
	return stuckHeight;
}

export function setStuckHeight(height: number): void {
	stuckHeight = height;
}

function setAnchor(newAnchor: HTMLElement | null): void {
	if (
		chunkNode === newAnchor ||
		(chunkNode != null &&
			utils.matchingChild(chunkNode, 'article:only-of-type') ===
				newAnchor)
	) {
		exactAnchorNode = null;
	} else {
		exactAnchorNode = newAnchor;
	}

	for (const node of levelNodes) {
		node.classList.remove('anchor');
	}

	anchorNode =
		exactAnchorNode?.localName === 'section'
			? exactAnchorNode.closest('article')
			: exactAnchorNode;
	if (anchorNode != null) {
		anchorNode.classList.add('anchor');
	}
}

function preparePage(): void {
	window.addEventListener(
		'scroll',
		throttle(() => {
			update({ scrolling: true });
		}, 250),
		{ passive: true },
	);
	window.addEventListener(
		'resize',
		debounce(() => {
			update({ resizing: true });
		}, 250),
		{ passive: true },
	);
	document.body.addEventListener('click', onClickLink);

	stuckHeight = undefined;
	const container = document.getElementById('container');
	if (container == null) {
		return;
	}

	const main = document.querySelector('main.nodes');
	if (main == null) {
		return;
	}

	levelNodes = utils.findElements(main, 'article');
	subsecNodes = utils.findElements(main, 'section');
	chunkNode = levelNodes[0] ?? null;
	// anchorNode and exactAnchorNode set below
	visibleNodes = [];
	currentNode = null;
	updating = false;

	// The anchor node is the node referenced in the canonical URL's anchor.
	const canonicalLink = document.head.querySelector<HTMLAnchorElement>(
		'link[rel="canonical"]',
	);
	const anchorMatch = canonicalLink?.href.match(/#([^#]+)$/);
	setAnchor(anchorMatch != null ? getByID(anchorMatch[1]!) : null);

	// Check for a real anchor in the actual URL.
	let trueAnchor: HTMLElement | null = null;
	if (location.hash !== '') {
		const trueAnchorID = location.hash.replace(/^#/, '');
		if (exactAnchorNode == null || trueAnchorID !== exactAnchorNode.id) {
			trueAnchor = getByID(trueAnchorID);
		} // not really a node
	}

	// Scroll to the real anchor, if any, else anchor node, if any, else to the
	// main container.
	const scrollTarget = trueAnchor ?? exactAnchorNode ?? container;
	scroll.intoView(scrollTarget);

	window.dispatchEvent(new Event('cpc:nodes:prepare-page'));

	update({ preparing: true, navigating: true });

	// Fix up anything that was slow getting a layout.
	setTimeout(() => {
		update({ preparing: true });
	}, 1000);

	for (const subsec of getSubsecNodes()) {
		prepareSubsec(subsec);
	}
}

// Check whether the visit is to a node on the same page. If so, cancel the
// visit and anchor/scroll instead.

function onClickLink(event: Event): void {
	const link = event.target;
	if (
		!(link instanceof HTMLAnchorElement) ||
		link.dataset['scrollable'] === 'false' ||
		link.target === '_blank'
	) {
		return;
	}

	if (levelNodes.length === 0) {
		return;
	}

	const anchorID = utils.getEquivalentAnchor(link.href);
	if (anchorID == null) {
		return;
	}

	const anchor = getByID(anchorID);
	if (anchor == null) {
		return;
	}

	event.preventDefault();

	window.dispatchEvent(new Event('cpc:nodes:navigate-on-page'));

	setAnchor(anchor);

	// Don't show __[hash] URLs generated in absence of intrinsic identifiers.
	if (!anchorID.includes('__')) {
		history.pushState(history.state, '', anchorID + location.search);
	}

	if (exactAnchorNode != null) {
		scroll.intoView(exactAnchorNode, { animate: false });
	}

	update({ navigating: true });
}

export interface IRunInfo {
	preparing: boolean;
	navigating: boolean;
	autoScrolling: boolean;
	recalculating: boolean;
	resizing: boolean;
	targetingAnchor: boolean;

	yMin: number;
	yMax: number;

	firstHeaderVisible: HTMLElement | null;

	anchorPassed: boolean;
	anchorVisible: boolean;

	foundStuckSubsec: boolean;
	fallbackCandidates: HTMLElement[];
}

interface IUpdateOptions {
	navigating?: boolean;
	preparing?: boolean;
	resizing?: boolean;
	scrolling?: boolean;
}

function update(options: IUpdateOptions): void {
	if (levelNodes.length === 0) {
		return;
	}

	if (updating) {
		return;
	}
	updating = true;

	const scrollTop = window.scrollY;

	const oldCurrent = currentNode;
	const oldVisible = visibleNodes;
	visibleNodes = [];

	options.preparing ??= false;
	options.navigating ??= false;
	options.resizing ??= false;

	const scrollAnimated = scroll.isAnimated();
	const runInfo: IRunInfo = {
		preparing: options.preparing,
		navigating: options.navigating,
		autoScrolling: scrollAnimated,
		resizing: options.resizing,
		recalculating: options.preparing || options.resizing,
		targetingAnchor:
			exactAnchorNode != null && (options.navigating || scrollAnimated),

		yMin: scrollTop + site.getTopStuckOffset(),
		yMax: scrollTop + window.innerHeight,

		firstHeaderVisible: null,

		anchorPassed: false,
		anchorVisible: false,

		foundStuckSubsec: false,
		fallbackCandidates: [],
	};

	if (exactAnchorNode != null && exactAnchorNode.localName !== 'article') {
		updateNode(runInfo, exactAnchorNode);
	}

	for (const node of levelNodes) {
		if (!updateNode(runInfo, node)) {
			break;
		}
	}

	// If the anchor node is not visible and the window isn't navigating or
	// scrolling, unset the anchor node.
	if (
		anchorNode != null &&
		!runInfo.anchorVisible &&
		!runInfo.navigating &&
		!runInfo.autoScrolling
	) {
		setAnchor(null);
	}

	// If there is no anchor node and no other node's header is partly visible,
	// the current node is the last partly visible level node that does not
	// start within the viewport. Failing that, the chunk node is used.
	currentNode =
		anchorNode ??
		runInfo.firstHeaderVisible ??
		runInfo.fallbackCandidates.at(-1) ??
		chunkNode;

	if (currentNode !== oldCurrent) {
		onChangeCurrent(runInfo);
	}

	// If the visible range has changed, trigger an event.
	if (
		visibleNodes[0] !== oldVisible[0] ||
		visibleNodes.at(-1) !== oldVisible.at(-1)
	) {
		window.dispatchEvent(new Event('cpc:nodes:change-visible'));
	}

	updating = false;
}

function onChangeCurrent(runInfo: IRunInfo): void {
	// If the current node has changed, trigger an event.
	window.dispatchEvent(new Event('cpc:nodes:change-current'));

	// Update the URL if not already navigating within the page.
	// Don't show __[hash] URLs generated in absence of intrinsic identifiers.
	if (currentNode == null) {
		return;
	}
	const currentID = currentNode.id;
	if (!runInfo.navigating && !currentID.includes('__')) {
		history.replaceState(history.state, '', currentID + location.search);
	}
}

export interface INodeInfo {
	node: HTMLElement;
	isSubsec: boolean;
	isAnchorExact: boolean;

	offsetHeight: number;
	stuckAncestorsHeight: number;

	nodeTop: number;
	nodeBottom: number;

	headerTop?: number;
	headerBottom?: number;
	headerHeight?: number;
}

function updateNode(
	runInfo: IRunInfo,
	node: HTMLElement & { cachedOffsetHeight?: number },
): boolean {
	// Measure the height of the node itself, with caching.
	if (runInfo.recalculating || node.cachedOffsetHeight == null) {
		node.cachedOffsetHeight = node.offsetHeight;
	}

	const stuckAncestorsHeight = utils.getStuckAncestorsHeight(node);
	const trueOffsetTop = utils.getTrueOffsetTop(node);

	const nodeInfo: INodeInfo = {
		node,
		isSubsec: node.localName === 'section',
		isAnchorExact: node === exactAnchorNode,

		offsetHeight: node.cachedOffsetHeight,
		stuckAncestorsHeight,

		nodeTop: trueOffsetTop - stuckAncestorsHeight,
		nodeBottom:
			trueOffsetTop - stuckAncestorsHeight + node.cachedOffsetHeight,
	};

	// If this is the exact anchor node, note its passage.
	if (nodeInfo.isAnchorExact) {
		runInfo.anchorPassed = true;
	}

	// Measure the node's header and update it as needed.
	updateHeader(runInfo, nodeInfo);

	// If the node is entirely below the viewport, there will be no more
	// visible nodes, so break the loop.
	if (nodeInfo.nodeTop > runInfo.yMax) {
		return false;
	}

	// If the node is entirely above the viewport, skip it but keep going.
	if (nodeInfo.nodeBottom < runInfo.yMin) {
		return true;
	}

	// Update the headers of any subsections within the level node.
	for (const subsec of utils.findElements(node, 'section')) {
		if (node !== subsec.closest('article')) {
			continue;
		}
		updateSubsec(runInfo, nodeInfo.headerHeight ?? 0, subsec);
	}

	// Consider all but the chunk itself for the visible range.
	if (node !== chunkNode) {
		visibleNodes.push(node);
		if (!nodeInfo.isSubsec && nodeInfo.nodeTop < runInfo.yMin) {
			runInfo.fallbackCandidates.push(node);
		}
	}

	// If this is the anchor node, note its visibility.
	if (node === anchorNode) {
		runInfo.anchorVisible = true;
	}

	// If there is no partly visible anchor node, the current node is the
	// first partly visible level node whose header (or top area, if it has
	// no header) is entirely visible.
	if (
		runInfo.firstHeaderVisible == null &&
		!nodeInfo.isSubsec &&
		(nodeInfo.headerTop ?? 0) >= runInfo.yMin &&
		(nodeInfo.headerBottom ?? 0) <= runInfo.yMax
	) {
		runInfo.firstHeaderVisible = node;
	}

	return true;
}

function updateSubsec(
	runInfo: IRunInfo,
	stuckLevelNodeHeight: number,
	node: HTMLElement,
): void {
	const header = getHeader(node);
	if (header == null) {
		return;
	}

	const headerTop = utils.getTrueOffsetTop(header) - stuckLevelNodeHeight;
	const headerBottom = headerTop + header.offsetHeight;
	const headerMid = (headerTop + headerBottom) / 2;

	// Update whether the header should be considered stuck, i.e.,
	// specially presented at the top of the viewport.
	const stuck =
		runInfo.navigating || runInfo.autoScrolling
			? node === getExactAnchor()
			: !runInfo.foundStuckSubsec && headerMid > runInfo.yMin;
	header.classList.toggle('stuck', stuck);
	if (stuck) {
		runInfo.foundStuckSubsec = true;
	}
}

function prepareSubsec(node: HTMLElement): void {
	const header = getHeader(node);
	if (header == null) {
		return;
	}
	if (node.id !== '' && !node.hasAttribute('aria-labelledby')) {
		// Designate any <name> as the ARIA label, if none exists already.
		const name = header.querySelector<HTMLElement>('.name');
		if (name != null && name.id === '') {
			name.id = `${node.id}__name`;
			node.setAttribute('aria-labelledby', name.id);
		}
	}

	for (const subcrumb of utils.matchingChildren(header, '.subcrumb')) {
		subcrumb.remove();
	}
	if (header.querySelector<HTMLElement>('.num') == null) {
		return;
	}

	const padding = window.getComputedStyle(node).paddingLeft;
	const showCrumbs: boolean =
		node.classList.contains('subsec') &&
		!node.classList.contains('subsec1') &&
		padding !== '' &&
		padding !== '0px';
	// HACK: Measuring padding here (and below) to deal with products that
	// don't indent their subsections, even on the web. They usually have
	// cumulative subsection numbers that make subcrumbs unhelpful anyway
	// (e.g., 1, 1.3, 1.3.2, 1.3.2.5).
	if (!showCrumbs) {
		return;
	}

	for (const ancestor of utils.matchingAncestors(node, 'section')) {
		if (!ancestor.classList.contains('subsec')) {
			continue;
		}

		const subcrumb = document.createElement('a');
		subcrumb.classList.add('subcrumb');
		subcrumb.tabIndex = -1;
		subcrumb.setAttribute('aria-hidden', 'true');
		subcrumb.href = ancestor.id;

		if (ancestor.dataset['cite'] != null) {
			subcrumb.setAttribute('title', ancestor.dataset['cite']);
		}

		const num = getHeader(ancestor)?.querySelector<HTMLElement>('.num');
		const numText = num != null ? utils.getCleanText(num) : '';
		if (
			numText !== '' &&
			numText.length <= 7 &&
			!/(\w\W*){4}/.test(numText)
		) {
			subcrumb.dataset['num'] = numText;
		}

		header.prepend(subcrumb);
	}
}

function updateHeader(runInfo: IRunInfo, nodeInfo: INodeInfo): void {
	// Measure the height of the node's header or, if no header, up to the
	// node's {5px..25px} range of height.
	const header = getHeader(nodeInfo.node);
	if (header != null) {
		// If the window was resized, the top offset may have changed.
		if (runInfo.resizing) {
			header.style.top = utils.pxToString(site.getTopStuckOffset());
		}

		// This will cause a node with a currently stuck header to remain
		// current all the way through. That makes sense, though, since
		// the sticky header makes it seem current anyway.
		nodeInfo.headerTop =
			utils.getTrueOffsetTop(header) - nodeInfo.stuckAncestorsHeight;
		nodeInfo.headerHeight = header.offsetHeight;
		nodeInfo.headerBottom = nodeInfo.headerTop + nodeInfo.headerHeight;

		// Update whether the header should be considered stuck, i.e.,
		// specially presented at the top of the viewport.
		const stuck = runInfo.targetingAnchor
			? !runInfo.anchorPassed
			: nodeInfo.nodeTop < runInfo.yMin;
		header.classList.toggle('stuck', stuck);
	} else {
		nodeInfo.headerTop = Math.min(
			nodeInfo.nodeTop + 5,
			nodeInfo.nodeBottom,
		);
		nodeInfo.headerBottom = Math.min(
			nodeInfo.nodeTop + 25,
			nodeInfo.nodeBottom,
		);
	}
}

export function getHeader(
	node: HTMLElement & { nodeHeader?: HTMLElement | null },
): HTMLElement | null {
	node.nodeHeader ??= utils.matchingChild(node, 'header, .header');

	if (node.nodeHeader == null) {
		const firstParagraph = utils.matchingChild(node, 'p:first-of-type');
		if (firstParagraph != null) {
			node.nodeHeader = utils.matchingChild(firstParagraph, '.header');
		}
	}

	return node.nodeHeader;
}
