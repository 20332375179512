import 'vanilla-cookieconsent';

const cc = initCookieConsent();

export type Category = 'necessary' | 'customization' | 'analytics';

export function isCategoryAllowed(category: Category): boolean {
	return cc.allowedCategory(category);
}

export function openDialog(): void {
	cc.showSettings(0);
}

window.addEventListener('cpc:prepare-page', preparePage);

function preparePage(): void {
	// This body class is the only way to control the module's dark mode.
	document.body.classList.toggle(
		'c_darkmode',
		matchMedia('(prefers-color-scheme: dark)').matches,
	);

	const domainName =
		/\.\w+\.\w+$/.exec(location.hostname)?.[0] ?? location.hostname;

	cc.run({
		auto_language: 'document',
		autoclear_cookies: true,
		current_lang: 'en',
		mode: 'opt-out',
		gui_options: {
			consent_modal: {
				swap_buttons: true,
			},
		},
		onAccept: () => {
			window.dispatchEvent(new Event('cpc:consent:update'));
		},
		onChange: () => {
			window.dispatchEvent(new Event('cpc:consent:update'));
		},
		languages: {
			en: {
				consent_modal: {
					title: 'Cookies',
					description:
						'This website uses cookies to function properly, to let you customize it, and to help us understand how it is used.',
					primary_btn: {
						text: 'OK',
						role: 'accept_all',
					},
					secondary_btn: {
						text: 'More choices',
						role: 'settings',
					},
				},
				settings_modal: {
					title: 'Cookie preferences',
					save_settings_btn: 'Save Preferences',
					accept_all_btn: 'Accept All',
					reject_all_btn: 'Reject All',
					close_btn_label: 'Close',
					cookie_table_headers: [
						{ col1: 'Name' },
						{ col2: 'Domain' },
						{ col3: 'Expiration' },
						{ col4: 'Description' },
					],
					blocks: [
						{
							title: 'Overview',
							description:
								'This website uses cookies to function properly, to let you customize it, and to help us understand how it is used. You can accept or reject each category. For more information, please see the <a href="https://www.iccsafe.org/about/icc-online-privacy-policy/" target="_blank" rel="external" class="cc-link">privacy policy</a>.',
						},
						{
							title: 'Strictly necessary cookies',
							description:
								'These cookies are essential for the proper functioning of the site.',
							toggle: {
								value: 'necessary',
								enabled: true,
								readonly: true,
							},
							cookie_table: [
								{
									col1: '_cpc_session',
									col2: location.hostname,
									col3: 'Browser session',
									col4: 'Secures the site against unauthorized requests.',
								},
								{
									col1: 'cc_cookie',
									col2: location.hostname,
									col3: 'Six months',
									col4: 'Tracks whether you have accepted or rejected cookies in this dialog.',
								},
								{
									col1: 'search_query',
									col2: location.hostname,
									col3: 'Browser session',
									col4: 'Allows you to move between search results and see highlighted matches.',
								},
								{
									col1: 'viewport',
									col2: location.hostname,
									col3: '30 days',
									col4: 'Adapts the site content to fit your device.',
								},
							],
						},
						{
							title: 'Customization cookies',
							description:
								'These cookies let you customize the behavior and appearance of the site to meet your needs.',
							toggle: {
								value: 'customization',
								enabled: true,
								readonly: false,
							},
							cookie_table: [
								{
									col1: 'contents',
									col2: location.hostname,
									col3: 'Indefinite',
									col4: 'Table of Contents setting',
								},
								{
									col1: 'chunking',
									col2: location.hostname,
									col3: 'Indefinite',
									col4: 'Amount of Text on Each Page setting',
								},
								{
									col1: 'animation',
									col2: location.hostname,
									col3: 'Indefinite',
									col4: 'Animation setting',
								},
								{
									col1: 'font_family',
									col2: location.hostname,
									col3: 'Indefinite',
									col4: 'Font Family setting',
								},
								{
									col1: 'text_size',
									col2: location.hostname,
									col3: 'Indefinite',
									col4: 'Text Size setting',
								},
								{
									col1: 'line_spacing',
									col2: location.hostname,
									col3: 'Indefinite',
									col4: 'Line Spacing setting',
								},
								{
									col1: 'color_theme',
									col2: location.hostname,
									col3: 'Indefinite',
									col4: 'Color Theme setting',
								},
							],
						},
						{
							title: 'Performance cookies',
							description:
								'These cookies help us understand how site visitors interact with content and features. All of the data is anonymized and cannot be used to identify you.',
							toggle: {
								value: 'analytics',
								enabled: false,
								readonly: false,
							},
							cookie_table: [
								{
									col1: '^_ga',
									col2: domainName,
									col3: 'Two years',
									col4: 'These cookies are part of the Google Analytics service.',
									is_regex: true,
								},
								{
									col1: '_gid',
									col2: domainName,
									col3: 'One day',
									col4: 'This cookie is part of the Google Analytics service.',
								},
							],
						},
					],
				},
			},
		},
	});
}
