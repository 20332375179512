import * as utils from './utils';
import Dynamodal from './dynamodal';
import * as tooltips from './tooltips';

let flashMessages: HTMLElement[] = [];

window.addEventListener('cpc:prepare-page', preparePage);
window.addEventListener('cpc:render-page', renderPage);

// The remainder of this module covers elements and components which are present
// on certain pages but do not merit a separate module.

function preparePage(): void {
	// Direct external/file links to new tab.
	for (const link of utils.findElements(
		document,
		'a[rel="external"], a.file',
	)) {
		link.setAttribute('target', '_blank');
		link.setAttribute('rel', 'external noopener');
	}

	const main = document.querySelector<HTMLElement>('main');
	// Prepare tooltips except on content pages.
	if (main != null && !main.classList.contains('main')) {
		tooltips.prepare(main, { container: '#container' });
	}

	// Hide tooltips on mouse click.
	document.addEventListener('click', (event): void => {
		if (
			!(event.target instanceof HTMLElement) ||
			event.target.dataset['toggle'] !== 'tooltip'
		) {
			return;
		}
		if (event.screenY === 0) {
			// Don't blur from keyboard activity.
			return;
		}
		event.target.blur();
		if (event.target.parentElement?.dataset['toggle'] != null) {
			event.target.parentElement.blur();
		}
	});

	// Remove and store any flash messages to be displayed in a modal later.
	const mainColumn = document.getElementById('main-column');
	flashMessages =
		mainColumn != null ? utils.matchingChildren(mainColumn, '.flash') : [];
	for (const flash of flashMessages) {
		flash.remove();
	}
}

function renderPage(): void {
	// Redisplay any flash messages in a modal.
	if (flashMessages.length > 0) {
		const modalContent = document.createElement('div');

		if (flashMessages.length === 1) {
			// If just one flash message, it will look better as a paragraph.
			const p = document.createElement('p');
			while (flashMessages[0]!.firstChild != null) {
				p.appendChild(flashMessages[0]!.firstChild);
			}
			modalContent.appendChild(p);
		} else {
			// Multiple flash messages must be kept separate for clarity.
			for (const flash of flashMessages) {
				modalContent.appendChild(flash);
			}
		}

		const isError = flashMessages.some((flash) =>
			flash.classList.contains('alert-danger'),
		);
		void Dynamodal.spawn(modalContent, {
			type: isError ? 'error' : 'notice',
		});

		flashMessages = [];
	}
}
