import { throttle, debounce } from 'underscore';
import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';
import * as utils from '../../javascript/utils';
import * as nodes from '../../javascript/nodes';
import * as scroll from '../../javascript/scroll';

application.register(
	'onthispage',
	class Onthispage extends Controller {
		private static readonly ITEMS_VISIBLE = 5;
		private static readonly MIDDLE_OFFSET = Math.floor(
			(Onthispage.ITEMS_VISIBLE - 1) / 2,
		);
		private static readonly SCROLL_INCREMENT = Math.min(
			4,
			Onthispage.MIDDLE_OFFSET,
		);

		public static override readonly targets = [
			'list',
			'item',
			'previous',
			'next',
		];

		private declare readonly listTarget: HTMLElement;
		private declare readonly itemTargets: HTMLElement[];

		private declare readonly previousTarget: HTMLElement;
		private declare readonly nextTarget: HTMLElement;

		private readonly resizeListener = throttle(() => {
			this.resize();
		}, 250);
		private readonly updateListener = debounce(() => {
			this.update();
		}, 250);

		public override connect(): void {
			window.addEventListener('cpc:prepare-page', () => {
				this.preparePage();
			});
		}

		public preparePage(): void {
			this.element.classList.add('onthispage--attached');
			this.resize();
			this.update();
			window.addEventListener('resize', this.resizeListener, {
				passive: true,
			});
			window.addEventListener(
				'cpc:nodes:change-current',
				this.updateListener,
			);
		}

		public override disconnect(): void {
			window.removeEventListener(
				'cpc:nodes:change-current',
				this.updateListener,
			);
			window.removeEventListener('resize', this.resizeListener);
			this.element.classList.remove('onthispage--attached');
		}

		private resize(): void {
			const itemHeight = this.itemTargets[0]?.offsetHeight ?? 0;
			if (itemHeight < 10) {
				// ignore if oddly short
				return;
			}

			const height = Onthispage.ITEMS_VISIBLE * itemHeight - 3;
			this.listTarget.style.height = utils.pxToString(height);
		}

		private update(): void {
			// Skip if there are no level nodes on the page or items in the list.
			if (nodes.getLevelNodes().length === 0) {
				return;
			}
			if (this.itemTargets.length === 0) {
				return;
			}

			const currentNode = nodes.getCurrent();
			const currentNodeID = currentNode?.id;
			const activeItem =
				currentNodeID != null
					? this.itemTargets.find(
							(item) => item.dataset['node'] === currentNodeID,
						)
					: null;

			for (const item of this.itemTargets) {
				item.classList.toggle('active', item === activeItem);
			}

			const topItem =
				activeItem != null
					? (utils.nthSibling(
							activeItem,
							-1 * Onthispage.MIDDLE_OFFSET,
						) as HTMLElement)
					: this.itemTargets[0];
			if (topItem != null) {
				this.setTopItem(topItem);
			}
		}

		public previous(event: Event): void {
			event.preventDefault();
			this.scrollItems('previous');
		}

		public next(event: Event): void {
			event.preventDefault();
			this.scrollItems('next');
		}

		private scrollItems(direction: 'previous' | 'next'): void {
			const oldTopItem = this.itemTargets.find((item) =>
				item.classList.contains('onthispage-item-top'),
			);
			if (oldTopItem == null) {
				return;
			}

			const directionMultiplier = direction === 'previous' ? -1 : 1;
			const newTopItem = utils.nthSibling(
				oldTopItem,
				directionMultiplier * Onthispage.SCROLL_INCREMENT,
			) as HTMLElement;

			if (newTopItem !== oldTopItem) {
				this.setTopItem(newTopItem);
			}
		}

		private setTopItem(topItem_: HTMLElement): void {
			let topItem = topItem_;

			// If there aren't enough items below the new top item to allow it to
			// be positioned at the top, offset backwards to one that can be.
			const nextCount = utils.followingSiblings(topItem).length;
			if (nextCount < Onthispage.ITEMS_VISIBLE - 1) {
				topItem = utils.nthSibling(
					topItem,
					nextCount - (Onthispage.ITEMS_VISIBLE - 1),
				) as HTMLElement;
			}

			for (const item of this.itemTargets) {
				item.classList.toggle('onthispage-item-top', item === topItem);
			}

			const targetY = topItem.offsetTop + 1; // account for top border
			const maxY =
				this.listTarget.scrollHeight - this.listTarget.clientHeight - 1; // account for bottom border
			const duration = scroll.animate(
				Math.min(targetY, maxY),
				this.listTarget,
			);

			const previousDisabled =
				utils.precedingSiblings(topItem).length === 0;
			if (!previousDisabled) {
				this.previousTarget.classList.remove('disabled');
			}

			const nextDisabled =
				utils.followingSiblings(topItem).length <=
				Onthispage.ITEMS_VISIBLE - 1;
			if (!nextDisabled) {
				this.nextTarget.classList.remove('disabled');
			}

			setTimeout(() => {
				this.previousTarget.classList.toggle(
					'disabled',
					previousDisabled,
				);
				this.nextTarget.classList.toggle('disabled', nextDisabled);
			}, duration);
		}
	},
);
