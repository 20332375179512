import { debounce } from 'underscore';
import * as dragscroll from 'dragscroll';
import { Controller } from '@hotwired/stimulus';

import application, {
	addControllerToElement,
	removeControllerFromElement,
} from '../../javascript/application';

import * as utils from '../../javascript/utils';
import * as scroll from '../../javascript/scroll';
import Dynamodal from '../../javascript/dynamodal';

application.register(
	'largeviewmodal',
	class Largeviewmodal extends Controller {
		public static override readonly targets = [
			'modalBody',
			'modalContent',
			'share',
		];

		private declare readonly modalBodyTarget: HTMLElement;
		private declare readonly modalContentTarget: HTMLElement;
		private declare readonly shareTarget: HTMLElement;

		private readonly resizeListener = debounce(() => {
			this.renderPage();
		}, 250);

		public override connect(): void {
			window.addEventListener('cpc:render-page', () => {
				this.renderPage();
				window.addEventListener('resize', this.resizeListener, {
					passive: true,
				});
			});

			this.element.addJQEventListener('show.bs.modal', (e) => {
				this.prepareModal(e);
			});
			this.element.addJQEventListener('shown.bs.modal', () => {
				this.onModalShown();
			});
			this.element.addJQEventListener('hidden.bs.modal', () => {
				this.resetModal();
			});

			const modalbody = this.modalBodyTarget;
			modalbody.addEventListener('click', (e) => {
				this.onClickLink(e);
			});
		}

		public override disconnect(): void {
			window.removeEventListener('resize', this.resizeListener);
		}

		public renderPage(): void {
			const main = document.querySelector('main.nodes');
			if (main == null) {
				return;
			}

			const tables = utils.findElements<HTMLTableElement>(
				main,
				'table:not(.floattable-header, .enactments)',
			);
			for (const table of tables) {
				this.prepareTable(table);
			}

			const pictures = utils.findElements(main, 'picture');
			for (const picture of pictures) {
				this.preparePicture(picture);
			}
		}

		public prepareTable(table: HTMLTableElement): void {
			const tableParent = table.parentElement;
			if (tableParent == null) {
				return;
			}

			let tableWrap = tableParent;
			if (!tableWrap.classList.contains('table-wrap')) {
				tableWrap = document.createElement('div');
				tableWrap.classList.add('table-wrap');
				tableParent.insertBefore(tableWrap, table);
				tableWrap.appendChild(table);

				// Move any <caption> out of the table entirely as a plain
				// <figcaption> so it won't stick or be clipped.
				const caption = utils.matchingChild(table, 'caption');
				if (caption != null) {
					const figcaption = document.createElement('figcaption');
					figcaption.classList.add('from-table');
					figcaption.style.maxWidth = utils.pxToString(
						table.offsetWidth,
					);
					tableWrap.before(figcaption);
					while (caption.firstChild != null) {
						figcaption.appendChild(caption.firstChild);
					}
					caption.remove();
				}
			}

			const isWrapped = tableWrap.classList.contains('large');

			// Sometimes getRequestedSize underestimates table width, reasons unknown.
			const tableWidth = Math.max(
				utils.getRequestedSize(table).width,
				table.offsetWidth,
			);
			const node = table.closest<HTMLElement>('article, .subsec1');
			const shouldBeWrapped =
				(node != null && node.offsetWidth < tableWidth) ||
				!utils.queryMediaRole(table);

			if (shouldBeWrapped) {
				removeControllerFromElement(table, 'floattable');

				if (!isWrapped) {
					tableWrap.classList.add('large');
					tableWrap.setAttribute('role', 'button');
					tableWrap.setAttribute('tabindex', '0');
					tableWrap.dataset['toggle'] = 'modal';
					tableWrap.dataset['target'] = '#large-view';
				}
			} // !shouldBeWrapped
			else {
				if (isWrapped) {
					tableWrap.classList.remove('large');
					tableWrap.removeAttribute('role');
					tableWrap.removeAttribute('tabindex');
					delete tableWrap.dataset['toggle'];
					delete tableWrap.dataset['target'];
				}

				const thead = utils.matchingChild(table, 'thead');
				// Don't float headers of trivially short tables.
				if (
					thead != null &&
					table.offsetHeight > 3 * thead.offsetHeight
				) {
					addControllerToElement(table, 'floattable');
				}
			}

			const figure = table.closest('figure');
			if (figure != null) {
				figure.classList.toggle(
					'has-large-table-wrap',
					figure.querySelector('.table-wrap.large') != null,
				);
			}
		}

		public preparePicture(picture: HTMLPictureElement): void {
			// The best available candidate for the web is the web winner.
			const winner = utils.matchingChild<
				HTMLImageElement | HTMLSourceElement
			>(picture, '.web-winner');
			if (winner == null) {
				return;
			}

			// The fallback candidate is the one <img> in the <picture>.
			const fallback = utils.matchingChild(picture, 'img');

			const winnerNotShown =
				fallback != null &&
				winner.src !==
					(fallback.currentSrc !== ''
						? fallback.currentSrc
						: fallback.src);
			const isWrapped = picture.classList.contains('picture-wrap');

			const winnerWidth = parseFloat(winner.style.width);
			const winnerRequestedSize = utils.getRequestedSize(winner);

			const node = picture.closest<HTMLElement>('article, .subsec1');
			const winnerOutsized =
				(node != null &&
					node.offsetWidth < winnerRequestedSize.width) ||
				(!isNaN(winnerWidth) &&
					winnerWidth + 10 < winnerRequestedSize.width) ||
				!utils.queryMediaRole(winner);

			const shouldBeWrapped = winnerNotShown || winnerOutsized;

			if (shouldBeWrapped && !isWrapped) {
				// Wrap the picture.
				picture.classList.add('picture-wrap');
				picture.dataset['target'] = '#large-view';
				picture.dataset['toggle'] = 'modal';
			} else if (!shouldBeWrapped && isWrapped) {
				// Unwrap the picture.
				picture.classList.remove('picture-wrap');
				delete picture.dataset['target'];
				delete picture.dataset['toggle'];
			}
		}

		// HACK: Intersecting relatedTarget property for Bootstrap's show.bs.modal event.
		public prepareModal(
			event: utils.JQEvent & { relatedTarget?: Element },
		): void {
			const eventTarget = event.relatedTarget;
			if (eventTarget == null) {
				return;
			}

			// If there is a PDF to show, the clone source is the <picture>. Otherwise,
			// also look for any <figure> ancestor.
			const hasFile =
				eventTarget.querySelector(
					'.web-winner[type="application/pdf"]',
				) != null;
			const cloneSource = hasFile
				? eventTarget
				: (eventTarget.closest('figure') ?? eventTarget);

			const clone = cloneSource.cloneNode(true) as HTMLElement;
			clone.classList.remove(
				'float',
				'left',
				'right',
				'has-large-table-wrap',
			);

			const tables = utils.findElementsOrSelf(clone, 'table');
			const pictures = utils.findElementsOrSelf(clone, 'picture');
			const files = pictures.filter((p) =>
				p.querySelector('.web-winner[type="application/pdf"]'),
			);

			this.resetModal();

			const modalContent = this.modalContentTarget;
			const modalBody = this.modalBodyTarget;

			const shareButton = this.shareTarget;
			let shareSource: string | null = null;

			if (tables.length > 0) {
				modalContent.classList.add('for-tables');
				modalBody.appendChild(clone);

				for (const table of tables) {
					this.prepareTableInModal(table);
				}
			} else if (files.length === 1) {
				// Only do this if there is exactly one.
				modalContent.classList.add('for-files');
				shareSource = this.prepareFileInModal(files[0]!, modalBody);
			} else if (pictures.length > 0) {
				modalContent.classList.add('for-pictures');
				modalBody.classList.add('dragscroll');
				modalBody.appendChild(clone);

				for (const picture of pictures) {
					shareSource ??= this.preparePictureInModal(picture);
				}
			}

			// Pick up add/remove of .dragscroll class.
			dragscroll.reset();

			if (shareSource != null && shareSource.includes('share-media')) {
				shareButton.style.display = 'block';
				shareButton.setAttribute('href', shareSource);
			} else {
				shareButton.style.display = 'none';
				shareButton.removeAttribute('href');
			}

			window.dispatchEvent(new Event('cpc:large-view:prepare-modal'));
		}

		private prepareTableInModal(table: HTMLTableElement): void {
			// Don't invoke the modal from within itself.
			const tableWrap =
				table.closest<HTMLDivElement>('.table-wrap.large');
			if (tableWrap != null) {
				tableWrap.classList.remove('large');
				tableWrap.removeAttribute('role');
				tableWrap.removeAttribute('tabindex');
				delete tableWrap.dataset['toggle'];
				delete tableWrap.dataset['target'];
			}
		}

		private prepareFileInModal(
			file: HTMLPictureElement,
			modalBody: HTMLElement,
		): string | null {
			const winner = file.querySelector('.web-winner');
			const source =
				winner?.getAttribute('srcset') ?? winner?.getAttribute('src');
			const type = winner?.getAttribute('type');

			if (source == null || type == null) {
				return null;
			}

			const object = document.createElement('object');
			object.setAttribute('data', source);
			object.setAttribute('type', type);
			modalBody.appendChild(object);

			return source.replace(/^media\//, 'share-media/');
		}

		private preparePictureInModal(
			picture: HTMLPictureElement,
		): string | null {
			// Don't invoke the modal from within itself.
			picture.classList.remove('picture-wrap');
			delete picture.dataset['target'];
			delete picture.dataset['toggle'];

			const winner = picture.querySelector('.web-winner');
			if (winner == null) {
				return null;
			}

			const source =
				winner.getAttribute('srcset') ?? winner.getAttribute('src');
			if (source == null) {
				return null;
			}

			picture.replaceWith(winner);

			if (winner.localName === 'source') {
				const img = document.createElement('img');
				img.setAttribute('src', source);
				const type = winner.getAttribute('type');
				if (type != null) {
					img.setAttribute('type', type);
				}
				winner.replaceWith(img);
			}

			return source.replace(/^media\//, 'share-media/');
		}

		public onModalShown(): void {
			const modalBody = this.modalBodyTarget;

			// Reset the scroll position to the top left.
			modalBody.scrollTop = 0;
			modalBody.scrollLeft = 0;

			this.floatModalHeaders();
		}

		public floatModalHeaders(): void {
			for (const table of utils.findElements<HTMLTableElement>(
				this.element,
				'table:not(.floattable-header)',
			)) {
				addControllerToElement(table, 'floattable');
			}
		}

		public onClickLink(event: MouseEvent): void {
			const modalBody = this.modalBodyTarget;

			if (
				!(event.target instanceof HTMLAnchorElement) ||
				!event.target.hasAttribute('href')
			) {
				return;
			}

			const anchorID = utils.getEquivalentAnchor(event.target.href);
			const anchor =
				anchorID != null
					? this.element.querySelector<HTMLElement>(
							`[id="${CSS.escape(anchorID)}"]`,
						)
					: null;

			if (anchor != null) {
				event.preventDefault();
				scroll.intoView(anchor, {
					container: modalBody,
					animate: true,
				});
			} else if (anchorID != null) {
				jQuery(this.element).modal('hide');
				this.element.addJQEventListener(
					'hidden.bs.modal',
					() => {
						const scrollTarget = document.getElementById(anchorID);
						if (scrollTarget != null) {
							scroll.intoView(scrollTarget);
						}
					},
					{ once: true },
				);
			} else {
				jQuery(this.element).modal('hide');
			}
		}

		public resetModal(): void {
			const modalContent = this.modalContentTarget;
			const modalBody = this.modalBodyTarget;

			modalContent.classList.remove(
				'for-tables',
				'for-files',
				'for-pictures',
			);

			while (modalBody.firstChild != null) {
				modalBody.firstChild.remove();
			}
			modalBody.classList.remove('dragscroll');

			for (const table of utils.findElements(this.element, 'table')) {
				removeControllerFromElement(table, 'floattable');
			}
		}

		public scrollModal(event: Event): void {
			let scrollTarget: 'top' | 'bottom' = 'top';
			if (
				event.target instanceof HTMLElement &&
				event.target.classList.contains('large-view-scroll-bottom')
			) {
				scrollTarget = 'bottom';
			}

			scroll.animate(scrollTarget, this.modalBodyTarget);
		}

		public shareContent(event: MouseEvent): void {
			if (!this.element.classList.contains('show')) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();

			const shareSource = this.shareTarget.getAttribute('href');
			if (shareSource == null) {
				return;
			}

			this.element.addJQEventListener(
				'hidden.bs.modal',
				() => {
					void Dynamodal.spawn(shareSource);
				},
				{ once: true },
			);

			jQuery(this.element).modal('hide');
		}
	},
);
