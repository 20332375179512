import * as utils from './utils';
import * as nodes from './nodes';

type Selections = Record<string, Record<string, boolean>>;
let selections: Selections = {};
let supported = false;

window.addEventListener('cpc:prepare-page', preparePage);
window.addEventListener('cpc:render-page', renderPage);
window.addEventListener('cpc:contents:prepare-modal', prepareContentsModal);

export function get(): object {
	return selections;
}

function preparePage(): void {
	try {
		const localSelections = JSON.parse(
			localStorage.getItem('cpc-selections') ?? '{}',
		) as unknown;
		if (typeof localSelections === 'object' && localSelections != null) {
			selections = localSelections as Selections;
		}
		supported = true;
	} catch (e) {
		selections = {};
	}

	document.body.classList.toggle('selections', supported);
}

function renderPage(): void {
	updateAll();
	const main = document.querySelector('main.nodes');
	if (main == null) {
		return;
	}
	main.addEventListener('click', (event): void => {
		if (
			!(event.target instanceof HTMLInputElement) ||
			!event.target.classList.contains('selections-selector')
		) {
			return;
		}
		onChangeNode(event.target);
	});
}

function prepareContentsModal(): void {
	const contentsDiv = document.getElementById('contents');
	if (contentsDiv == null) {
		return;
	}
	contentsDiv.addEventListener('click', (event): void => {
		if (
			!(event.target instanceof HTMLInputElement) ||
			!event.target.classList.contains('selections-selector')
		) {
			return;
		}
		onChangeTOCItem(event.target);
	});
}

export function show(): void {
	const contentsSelections = document.getElementById('contents-selections');
	if (
		contentsSelections != null &&
		!contentsSelections.classList.contains('active')
	) {
		jQuery('#contents-selections-tab').tab('show');
	} else if (!isSidebarDisplayed()) {
		jQuery('#contents').modal('show');
	}
}

export function updateAll(): void {
	for (const productID of Object.keys(selections)) {
		for (const nodeID of Object.keys(selections[productID]!)) {
			updateNode(productID, nodeID, selections[productID]![nodeID]!);
		}
	}

	updateCommon();
}

function updateCommon(): void {
	const count = Object.keys(selections)
		.map((productID) => Object.keys(selections[productID]!).length)
		.reduce((a, b) => a + b, 0);

	const countLabel = count > 0 ? count.toString() : '';

	const selectionsButton = document.getElementById('selections');
	if (selectionsButton != null) {
		selectionsButton.classList.toggle('d-none', count === 0);
	}

	const selectionsItems = utils.findElements(
		document,
		`.sitenavbar-selections, #contents-share-selections,
		#contents-clear-selections`,
	);
	for (const item of selectionsItems) {
		item.classList.toggle('disabled', count === 0);
	}

	const selectionsCounts = utils.findElements(
		document,
		`#selections .selections-count, .sitenavbar-selections .badge,
		#contents-selections-tab .selections-count`,
	);
	for (const countSpan of selectionsCounts) {
		countSpan.innerText = countLabel;
	}
}

function updateNode(
	productID: string,
	nodeID: string,
	newValue: boolean,
): void {
	const selectors: (HTMLInputElement | null)[] = [];

	// Find the node's selector in the product's tab of Contents.

	const contentsProductTab = document.getElementById(
		`contents-product-${CSS.escape(productID)}`,
	);
	selectors.push(
		contentsProductTab?.querySelector(
			`[data-identifier="${CSS.escape(nodeID)}"] > .selections-selector`,
		) ?? null,
	);

	// Find the node's selector in the Selections tab of Contents.

	const selectionsTab = document.getElementById('contents-selections');
	const selectionsTabProduct = selectionsTab?.querySelector(
		`[data-product="${CSS.escape(productID)}"]`,
	);
	selectors.push(
		selectionsTabProduct?.querySelector(
			`[data-identifier="${CSS.escape(nodeID)}"] > .selections-selector`,
		) ?? null,
	);

	// Update the node's status in its actual presence on the page, if any.

	if (document.body.dataset['product'] === productID) {
		const node = nodes.getByID(nodeID);
		if (node != null) {
			node.classList.toggle('levelnodeheaders-selected', newValue);

			const header = nodes.getHeader(node);
			if (header != null) {
				selectors.push(header.querySelector('.selections-selector'));
			}

			const indicator = utils.matchingChild(
				node,
				'.track-indicator.selected',
			);
			if (indicator != null) {
				indicator.classList.toggle('d-none', !newValue);
			}
		}
	}

	// Update the node's selectedness in all its selectors.

	for (const selector of selectors) {
		if (selector != null) {
			selector.checked = newValue;
		}
	}
}

function save(): void {
	try {
		localStorage.setItem('cpc-selections', JSON.stringify(selections));
	} catch (error) {
		document.body.classList.remove('selections');
	}
}

function onChangeNode(selector: HTMLInputElement): void {
	const productID = document.body.dataset['product'];
	const node = selector.closest('article');
	if (productID != null && node != null) {
		onChange(productID, node.id, selector.checked);
	}
}

function isSidebarDisplayed(): boolean {
	const contents = document.getElementById('contents');
	if (contents == null) {
		return false;
	}
	return contents.classList.contains('contents-sidebar');
}

function onChangeTOCItem(selector: HTMLInputElement): void {
	const tocItem = selector.parentElement;
	if (tocItem == null) {
		return;
	}

	let productID = tocItem.dataset['product'];
	if (productID == null) {
		const tabPane = selector.closest<HTMLElement>('.tab-pane');
		productID = tabPane?.dataset['scope'];
	}

	const nodeID = tocItem.dataset['identifier'];

	if (productID != null && nodeID != null) {
		onChange(productID, nodeID, selector.checked);
	}
}

function onChange(productID: string, nodeID: string, newValue: boolean): void {
	if (!(productID in selections)) {
		selections[productID] = {};
	}
	if (newValue === (selections[productID]![nodeID] ?? false)) {
		return;
	}

	if (newValue) {
		selections[productID]![nodeID] = true;
	} else {
		delete selections[productID]![nodeID];
	}

	updateNode(productID, nodeID, newValue);
	updateCommon();
	save();
}

export function clear(): void {
	for (const productID of Object.keys(selections)) {
		for (const nodeID of Object.keys(selections[productID]!)) {
			updateNode(productID, nodeID, false);
		}
	}

	selections = {};
	updateCommon();
	save();
}
