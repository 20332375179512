import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

import * as utils from '../../javascript/utils';
import * as site from '../../javascript/site';
import * as tooltips from '../../javascript/tooltips';

application.register(
	'container',
	class Container extends Controller {
		public static override readonly targets = ['sidebar'];

		private declare readonly sidebarTargets: HTMLElement[];

		public override connect(): void {
			window.addEventListener('cpc:prepare-page', () => {
				this.preparePage();
			});
		}

		private preparePage(): void {
			for (const sidebar of this.sidebarTargets) {
				const sidebarTop = site.getTopStuckOffset() + 15;
				sidebar.style.top = utils.pxToString(sidebarTop);
				tooltips.prepare(sidebar, { container: sidebar });
			}
		}
	},
);
