import * as utils from './utils';

// Sets up elements in a container for Bootstrap tooltips with ARIA support.

export interface ITooltipsOptions extends Bootstrap.TooltipOption {
	destroyExisting?: boolean;
}

export function prepare(
	within: HTMLElement,
	options: ITooltipsOptions = {},
): void {
	const tooltips = utils.findElements(
		within,
		(options.selector === false ? undefined : options.selector) ??
			'[data-toggle="tooltip"]',
	);
	delete options.selector;

	// Tooltips are often used on dropdowns, so make sure a click hides them.
	for (const tip of tooltips) {
		tip.addEventListener('click', () => jQuery(tip).tooltip('hide'));
	}

	if (options.destroyExisting ?? false) {
		jQuery(tooltips).tooltip('dispose');
	}
	delete options.destroyExisting;

	// Set sensible defaults.
	options.container ??= 'body';
	options.placement ??= 'auto';
	if (options.trigger == null && isMobileSafari()) {
		// Safari on iOS: tooltips interfere with taps, so only show them on hover.
		options.trigger = 'manual';
	}

	jQuery(tooltips).tooltip(options);

	for (const tooltip of tooltips) {
		const originalTitle = tooltip.dataset['originalTitle'];
		if (originalTitle == null) {
			continue;
		}

		const aInSpan =
			tooltip.localName === 'span'
				? Array.from(tooltip.children).find((el) =>
						el.matches('a:only-child'),
					)
				: null;
		const target = aInSpan ?? tooltip;
		if (!target.hasAttribute('aria-label')) {
			target.setAttribute('aria-label', originalTitle);
		}
	}
}

// Detects Apple Safari on mobile (iOS) devices.

function isMobileSafari(): boolean {
	const isSafari = /Version\/[\d.].*Safari/.test(navigator.userAgent);
	const isIOSDevice = /^i(Phone|Pod|Pad)$/.test(navigator.platform);
	return isSafari && isIOSDevice;
}
