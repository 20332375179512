import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';
import * as utils from '../../javascript/utils';
import * as selections from '../../javascript/selections';

application.register(
	'tocitem',
	class Tocitem extends Controller {
		public static override readonly targets = ['branch'];

		private declare readonly branchTarget: HTMLElement;
		private declare readonly hasBranchTarget: boolean;

		protected async collapseAll(): Promise<void> {
			await this.toggleBranch(false);
		}

		protected async expandAll(): Promise<void> {
			await this.toggleBranch(true, true);
		}

		private async toggleBranch(
			rawState?: boolean,
			recursive?: boolean,
		): Promise<void> {
			if (!this.hasBranchTarget) {
				return;
			}

			// If invoked with an event arg by Stimulus, ignore that.
			const state = typeof rawState === 'boolean' ? rawState : undefined;

			const open = this.element.classList.toggle('tocitem--open', state);
			this.element.setAttribute('aria-expanded', open.toString());

			if (open) {
				await this.populateBranch(recursive);
			}
		}

		private async populateBranch(recursive = false): Promise<void> {
			if (!this.hasBranchTarget) {
				return;
			}
			const branch = this.branchTarget;

			if (
				branch.classList.contains('tocitem-branch--loading') ||
				branch.classList.contains('tocitem-branch--loaded')
			) {
				return;
			}

			let source = branch.dataset['source'];
			if (source == null) {
				return;
			}

			if (recursive) {
				source += '&open=true';
			}

			branch.classList.add('tocitem-branch--loading');

			window.dispatchEvent(
				new CustomEvent('cpc:request-start', { detail: false }),
			);

			let response: Response;
			try {
				response = await fetch(source, { method: 'GET' });
				if (!response.ok) {
					throw new Error(response.statusText);
				}
			} finally {
				window.dispatchEvent(
					new CustomEvent('cpc:request-end', { detail: false }),
				);
			}

			const parser = new DOMParser();
			const parsedContent = parser.parseFromString(
				await response.text(),
				'text/html',
			);
			const contentMain =
				parsedContent.querySelector<HTMLElement>('main');
			if (contentMain == null) {
				return;
			}

			const list = utils.matchingChild(contentMain, 'ul');
			if (list == null) {
				return;
			}

			branch.classList.remove('tocitem-branch--loading');
			branch.classList.add('tocitem-branch--loaded');

			while (branch.firstChild != null) {
				branch.firstChild.remove();
			}
			for (const item of Array.from(list.children)) {
				branch.appendChild(item);
			}

			selections.updateAll();
		}
	},
);
