import { Controller } from '@hotwired/stimulus';
import { debounce } from 'underscore';

import application from '../../javascript/application';

import * as utils from '../../javascript/utils';
import * as site from '../../javascript/site';

interface IFakeColData {
	bodyCol: HTMLTableColElement;
	headerCol: HTMLTableColElement;
	width?: number;
}

export default class Floattable extends Controller<HTMLTableElement> {
	private readonly resizeListener = debounce((): void => {
		this.update();
	}, 25);

	public override connect(): void {
		window.addEventListener('cpc:prepare-page', () => {
			this.preceding = undefined;
			this.update();
		});
		window.addEventListener('resize', this.resizeListener, {
			passive: true,
		});
		this.float();
	}

	public override disconnect(): void {
		window.removeEventListener('resize', this.resizeListener);
		this.header?.remove();
		this.header = null;
		this.floated = false;
	}

	public get floated(): boolean {
		return this.element.classList.contains('floattable--floated');
	}

	private set floated(value: boolean) {
		this.element.classList.toggle('floattable--floated', value);
	}

	public get headerHeight(): number {
		return this.header?.offsetHeight ?? 0;
	}

	private header: HTMLTableElement | null = null;

	private float(): void {
		// There must be a header row group.
		const thead = utils.matchingChild(this.element, 'thead');
		if (thead == null) {
			return;
		}

		// Persist the original column width specs for later reuse.
		for (const col of getColsForTable(this.element)) {
			col.dataset['origWidth'] = col.style.width;
		}

		// Create the header table as a shallow clone.
		this.header = this.element.cloneNode(false) as HTMLTableElement;
		this.header.classList.add('floattable-header');
		delete this.header.dataset['controller'];

		// Copy the columns and header row group into the header table.
		for (const child of this.element.querySelectorAll('colgroup, thead')) {
			this.header.appendChild(child.cloneNode(true));
		}

		// Add the header table to the DOM and mark the body table as
		// floated. Place the header outside of any responsive table wrapper
		// so it can work properly.
		if (
			this.element.parentElement != null &&
			Array.from(this.element.parentElement.classList).some((klass) =>
				klass.startsWith('table-responsive'),
			)
		) {
			this.element.parentElement.before(this.header);
		} else {
			this.element.before(this.header);
		}
		this.floated = true;

		// Run calculations for the first time.
		this.update();
	}

	private preceding?: number;

	private measurePreceding(): number {
		if (this.element.closest('.largeviewmodal') != null) {
			return 0;
		}
		this.preceding ??=
			site.getTopStuckOffset() +
			utils.getStuckAncestorsHeight(this.element);
		return this.preceding;
	}

	private update(): void {
		if (!this.floated || this.header == null) {
			return;
		}

		// Construct a fake row of cells for column measurement. Temporarily
		// remove floated styling from the body table and restore the
		// original column width specs.

		const bodyCols = getColsForTable(this.element);
		const headerCols = getColsForTable(this.header);
		const sharedLength = Math.min(bodyCols.length, headerCols.length);

		const fakeRowGroup = document.createElement('tbody');
		const fakeRow = document.createElement('tr');
		fakeRowGroup.appendChild(fakeRow);

		const fakeCellMap = new Map<HTMLTableCellElement, IFakeColData>();
		for (let i = 0; i < sharedLength; ++i) {
			const fakeCell = document.createElement('td');
			fakeRow.appendChild(fakeCell);

			const bodyCol = bodyCols[i]!;
			bodyCol.style.width = bodyCol.dataset['origWidth'] ?? '';

			const headerCol = headerCols[i]!;
			headerCol.style.width = headerCol.dataset['origWidth'] ?? '';

			fakeCellMap.set(fakeCell, { bodyCol, headerCol });
		}

		this.element.appendChild(fakeRowGroup);
		this.floated = false;

		// Measure the table and fake cells. (Don't update yet to avoid interactions.)

		const tableWidth = this.element.getBoundingClientRect().width;

		for (const [fakeCell, data] of fakeCellMap) {
			data.width = fakeCell.getBoundingClientRect().width;
		}

		// Update the table and column widths.

		this.element.style.width = utils.pxToString(tableWidth);
		this.header.style.width = utils.pxToString(tableWidth);

		for (const [_fakeCell, data] of fakeCellMap) {
			data.bodyCol.style.width = utils.pxToString(data.width!);
			data.headerCol.style.width = utils.pxToString(data.width!);
		}

		// Update the header top position.

		this.header.style.top = utils.pxToString(this.measurePreceding());

		// Restore the header and body tables to their usual states.

		fakeRowGroup.remove();
		this.floated = true;
	}
}

application.register('floattable', Floattable);

function getColsForTable(table: HTMLTableElement): HTMLTableColElement[] {
	return utils
		.matchingChildren(table, 'colgroup')
		.flatMap((colgroup) => utils.matchingChildren(colgroup, 'col'));
}
