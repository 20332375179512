import * as utils from './utils';
import * as nodes from './nodes';
import * as scroll from './scroll';

export interface IMarkNavOptions {
	matchSelector: string;
	desktopNav: HTMLElement;
	mobileNav: HTMLElement;
	itemNav?: HTMLElement;
	matchNav: HTMLElement;
}

export default class MarkNav {
	public readonly matchSelector: string;
	public readonly matches: HTMLElement[] = [];

	public currentMatch: HTMLElement | null = null;
	public currentIndex: number | null = null;

	public readonly navDesktop: HTMLElement;

	public readonly navItems: HTMLElement | null = null;
	public readonly navPrevItem: HTMLElement | null = null;
	public readonly navNextItem: HTMLElement | null = null;

	public readonly navMatches: HTMLElement;
	public readonly navPrevMatch: HTMLElement;
	public readonly navNextMatch: HTMLElement;

	public readonly navMobile: HTMLElement;
	public readonly navPrevMobile: HTMLElement;
	public readonly navNextMobile: HTMLElement;

	public largeViewMatches: HTMLElement[] = [];

	public constructor(options: IMarkNavOptions) {
		this.matchSelector = options.matchSelector;

		const matchArea = document.querySelector('#main-column');
		if (matchArea == null) {
			throw new Error('There is no navigable content on the page.');
		}
		this.matches = utils.findElements(matchArea, this.matchSelector);

		this.navDesktop = options.desktopNav;

		const navMatchesPrev =
			options.matchNav.querySelector<HTMLElement>('.previous');
		const navMatchesNext =
			options.matchNav.querySelector<HTMLElement>('.next');
		const navMobilePrev =
			options.mobileNav.querySelector<HTMLElement>('.previous');
		const navMobileNext =
			options.mobileNav.querySelector<HTMLElement>('.next');

		if (
			navMatchesPrev == null ||
			navMatchesNext == null ||
			navMobilePrev == null ||
			navMobileNext == null
		) {
			throw new Error('Match navigation button(s) are missing.');
		}

		this.navMatches = options.matchNav;
		this.navPrevMatch = navMatchesPrev;
		this.navNextMatch = navMatchesNext;

		this.navMobile = options.mobileNav;
		this.navPrevMobile = navMobilePrev;
		this.navNextMobile = navMobileNext;

		if (options.itemNav != null) {
			this.navItems = options.itemNav;
			this.navPrevItem = this.navItems.querySelector('.previous');
			this.navNextItem = this.navItems.querySelector('.next');
			if (this.navPrevItem == null || this.navNextItem == null) {
				throw new Error('Match navigation button(s) are missing.');
			}

			// HACK: Item back/forward links can't use in-page autoscrolling due
			// to issues with subsequent matches on the same page.
			this.navPrevItem.dataset['scrollable'] = 'false';
			this.navNextItem.dataset['scrollable'] = 'false';

			this.navItems.addEventListener('click', (event): void => {
				if (
					!(event.target instanceof HTMLElement) ||
					!event.target.matches('.current')
				) {
					return;
				}
				this.setCurrentMatch(0, true);
			});
		}

		this.preparePage();
	}

	private preparePage(): void {
		this.prepareEventHandlers(this.navMatches);
		this.prepareEventHandlers(this.navMobile);

		this.matches.forEach((match, index) => {
			match.dataset['index'] = index.toString();
		});

		this.navMatches.classList.toggle('d-sm-flex', this.matches.length > 0);

		const matchCount = this.navMatches.querySelector('.match-count');
		if (matchCount != null) {
			matchCount.textContent = this.matches.length.toString();
		}

		if (this.matches.length > 0) {
			// The first match on the page is the current match...
			let targetMatch = 0;

			// ...unless navigating to a specific node on the page as part of
			// a mark system with page navigation, in which case the first match
			// (if any) in that specific node is the current match.
			const anchorExact = nodes.getExactAnchor();
			if (anchorExact != null && this.navItems != null) {
				const matchesInAnchor = utils.findElements(
					anchorExact,
					this.matchSelector,
				);

				targetMatch =
					matchesInAnchor.length > 0
						? this.matches.indexOf(matchesInAnchor[0]!)
						: -1;
			}

			setTimeout((): void => {
				if (targetMatch !== -1) {
					this.setCurrentMatch(targetMatch, true);
				}
			}, 200);
		}
	}

	public prepareLargeView(): void {
		const largeViewModal = document.getElementById('large-view');
		if (largeViewModal == null) {
			return;
		}

		const modalHeader = largeViewModal.querySelector('.modal-header');
		const modalBody = largeViewModal.querySelector('.modal-body');
		if (modalHeader == null || modalBody == null) {
			return;
		}

		modalHeader.after(this.navDesktop);
		modalBody.appendChild(this.navMobile);

		largeViewModal.addJQEventListener(
			'shown.bs.modal',
			() => {
				this.largeViewMatches = utils.findElements(
					largeViewModal,
					this.matchSelector,
				);

				const largeViewMatch = this.getCurrentLargeViewMatch();
				if (largeViewMatch != null) {
					this.revealCurrentMatch();
				}

				this.prepareLargeViewMobileNavEventHandlers();
			},
			{ once: true },
		);

		largeViewModal.addJQEventListener(
			'hidden.bs.modal',
			() => {
				this.largeViewMatches = [];

				const navbars = document.getElementById('navbars');
				if (navbars == null) {
					throw new Error('Navigation bars are missing.');
				}
				navbars.appendChild(this.navDesktop);
				navbars.appendChild(this.navMobile);

				this.prepareLargeViewMobileNavEventHandlers();
			},
			{ once: true },
		);
	}

	private onNavigationClick(event: Event): void {
		if (!(event.target instanceof HTMLElement)) {
			return;
		}

		if (event.target.matches('.previous')) {
			this.setCurrentMatch(
				this.currentIndex == null ? 0 : this.currentIndex - 1,
				true,
			);
		}

		if (event.target.matches('.current')) {
			this.revealCurrentMatch();
		}

		if (event.target.matches('.next')) {
			this.setCurrentMatch(
				this.currentIndex == null ? 0 : this.currentIndex + 1,
				true,
			);
		}
	}

	private prepareEventHandlers(group: HTMLElement): void {
		group.addEventListener('click', (e) => {
			this.onNavigationClick(e);
		});
	}

	private prepareLargeViewMobileNavEventHandlers(): void {
		this.navMobile.removeEventListener('click', (e) => {
			this.onNavigationClick(e);
		});
		this.prepareEventHandlers(this.navMobile);
	}

	private setCurrentMatch(index: number, reveal: boolean): void {
		if (this.navItems != null) {
			if (index < 0) {
				this.navPrevItem?.click();
				return;
			} else if (index >= this.matches.length) {
				this.navNextItem?.click();
				return;
			}
		}

		this.currentIndex = index;
		this.currentMatch = this.matches[index] ?? null;

		for (const match of this.matches) {
			match.classList.toggle('current', match === this.currentMatch);
		}

		const largeViewMatch = this.getCurrentLargeViewMatch();
		for (const match of this.largeViewMatches) {
			match.classList.toggle('current', match === largeViewMatch);
		}

		const navCurrentMatch = this.navMatches.querySelector('.current-match');
		if (navCurrentMatch != null) {
			navCurrentMatch.textContent = (this.currentIndex + 1).toString();
		}

		this.togglePrevNextDisabled();

		if (reveal) {
			this.revealCurrentMatch();
		}
	}

	private togglePrevNextDisabled(): void {
		const isFirstMatch = this.currentIndex === 0;
		const isLastMatch = this.currentIndex === this.matches.length - 1;

		this.navPrevMatch.classList.toggle('disabled', isFirstMatch);
		this.navNextMatch.classList.toggle('disabled', isLastMatch);

		if (this.navItems != null) {
			this.navPrevMobile.classList.toggle(
				'disabled',
				isFirstMatch &&
					this.navPrevItem?.classList.contains('disabled'),
			);
			this.navNextMobile.classList.toggle(
				'disabled',
				isLastMatch && this.navNextItem?.classList.contains('disabled'),
			);
		} else {
			this.navPrevMobile.classList.toggle('disabled', isFirstMatch);
			this.navNextMobile.classList.toggle('disabled', isLastMatch);
		}
	}

	private revealCurrentMatch(): void {
		if (this.currentMatch == null) {
			return;
		}
		const largeViewModal = document.getElementById('large-view');

		if (
			largeViewModal != null &&
			largeViewModal.classList.contains('show')
		) {
			const largeViewMatch = this.getCurrentLargeViewMatch();
			if (largeViewMatch != null) {
				this.currentMatch = largeViewMatch;
			} else {
				jQuery(largeViewModal).modal('hide');
			}
		} else {
			// If the match is in a table that should be shown in large-view,
			// open large-view through the handler on the wrapper div.
			const tableWrap =
				this.currentMatch.closest<HTMLElement>('.table-wrap.large');
			if (tableWrap != null) {
				tableWrap.click();
				return;
			}
		}

		// If the match is in a <details>, it must be open/expanded.
		const details = utils.matchingAncestors(this.currentMatch, 'details');
		for (const detail of details) {
			detail.setAttribute('open', 'true');
		}
		scroll.intoView(this.currentMatch);
	}

	private getCurrentLargeViewMatch(): HTMLElement | null {
		const largeViewModal = document.getElementById('large-view');
		if (
			largeViewModal != null &&
			largeViewModal.classList.contains('show')
		) {
			return null;
		}
		return (
			this.largeViewMatches.find(
				(match) =>
					+(match.dataset['index'] ?? -1) === this.currentIndex,
			) ?? null
		);
	}
} // class MarkNav
