import { Controller } from '@hotwired/stimulus';
import { debounce } from 'underscore';

import application from '../../javascript/application';
import * as utils from '../../javascript/utils';
import * as nodes from '../../javascript/nodes';

application.register(
	'breadcrumbs',
	class Breadcrumbs extends Controller {
		public static readonly classes = ['compact', 'main'];

		private declare readonly compactClass: string;
		private declare readonly mainClass: string;

		public static override readonly targets = [
			'chunk',
			'onpage',
			'onpagelink',
		];

		private declare readonly chunkTarget: HTMLElement;
		private declare readonly hasChunkTarget: boolean;

		private declare readonly onpageTarget: HTMLElement;
		private declare readonly hasOnpageTarget: boolean;

		private declare readonly onpagelinkTarget: HTMLAnchorElement;
		private declare readonly hasOnpagelinkTarget: boolean;

		private readonly updateListener = debounce(() => {
			this.update();
		}, 250);

		public override connect(): void {
			this.update();
			window.addEventListener(
				'cpc:nodes:change-current',
				this.updateListener,
			);
		}

		public override disconnect(): void {
			window.removeEventListener(
				'cpc:nodes:change-current',
				this.updateListener,
			);
		}

		public update(): void {
			if (nodes.getLevelNodes().length === 0) {
				return;
			}
			if (!this.hasOnpageTarget) {
				return;
			}
			if (!this.element.classList.contains(this.compactClass)) {
				return;
			}
			const currentNode = nodes.getCurrent();
			const chunkIsCurrent = currentNode === nodes.getChunk();
			if (this.hasChunkTarget) {
				this.chunkTarget.classList.toggle('current', chunkIsCurrent);
			}
			this.onpageTarget.classList.toggle('hidden', chunkIsCurrent);

			if (
				!chunkIsCurrent &&
				currentNode != null &&
				this.hasOnpagelinkTarget
			) {
				const onpageLink = this.onpagelinkTarget;
				onpageLink.href = currentNode.id;

				const shortCite = currentNode.dataset['shortCite'];

				const currentHeader = nodes.getHeader(currentNode);
				const currentName =
					currentHeader?.querySelector<HTMLElement>('.name');
				const name =
					currentName != null
						? utils.getCleanText(currentName)
						: undefined;

				onpageLink.innerText =
					shortCite != null && name != null
						? `${shortCite}, ${name}`
						: (shortCite ?? name ?? '—');
			}
		}
	},
);
