import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';
import * as tooltips from '../../javascript/tooltips';

application.register(
	'nearby',
	class Nearby extends Controller<HTMLElement> {
		public override connect(): void {
			tooltips.prepare(this.element, {
				selector: '.previous[data-toggle="tooltip"]',
				destroyExisting: true,
				placement: 'right',
				container: this.element.offsetParent ?? undefined,
			});

			tooltips.prepare(this.element, {
				selector: '.current[data-toggle="tooltip"]',
				destroyExisting: true,
				placement: 'top',
				container: this.element.offsetParent ?? undefined,
			});

			tooltips.prepare(this.element, {
				selector: '.next[data-toggle="tooltip"]',
				destroyExisting: true,
				placement: 'left',
				container: this.element.offsetParent ?? undefined,
			});
		}
	},
);
