import { uniqueId } from 'underscore';

import * as utils from './utils-plain';
import * as nodes from './nodes';
export * from './utils-plain';

// Does nothing.

export function noop(): void {
	// ...
}

// Detects Internet Explorer.

export function isIE(): boolean {
	// Keep this for showing the IE warning.
	return (
		navigator.userAgent.includes('MSIE') ||
		navigator.userAgent.includes('Trident') ||
		Object.prototype.hasOwnProperty.call(document, 'documentMode')
	);
}

// Makes a jQuery event available to plain-DOM-style event listeners.

export type JQEvent =
	| Event
	| JQuery.TriggeredEvent<EventTarget, undefined, EventTarget, EventTarget>;

declare global {
	interface EventTarget {
		addJQEventListener(
			type: string,
			listener: (event: JQEvent) => void,
			options?: AddEventListenerOptions,
		): void;
	}
}

function addJQEventListener(
	this: EventTarget,
	type: string,
	listener: (event: JQEvent) => void,
	options?: AddEventListenerOptions,
): void {
	if (options?.once ?? false) {
		jQuery(this).one(type, listener);
	} else {
		jQuery(this).on(type, listener);
	}
}

EventTarget.prototype.addJQEventListener = addJQEventListener;

// Returns an element's inner text with comparison deletions removed.

export function getCleanText(element: HTMLElement): string {
	if (element.querySelector('del') == null) {
		return element.innerText;
	}
	const clone = element.cloneNode(true) as HTMLElement;
	for (const del of utils.findElements(clone, 'del')) {
		del.remove();
	}
	return clone.innerText;
}

// Returns the in-page anchor that a URL represents, if that URL is identical to
// the current URL except for the final path element and any query or anchor.

export function getEquivalentAnchor(url: string): string | null {
	const prefix = location.href.replace(/\/[^/]+$/, '');
	const match = /^(.+)\/([^/?#]+)(?:\?[^#]*)?(?:#(.+))?$/.exec(url);
	if (match == null || match[1] !== prefix) {
		return null;
	}
	return decodeURIComponent(match[3] ?? match[2]!);
}

// Calculates an element's vertical offset relative to the document instead of
// the viewport.

export function getTrueOffsetTop(element: HTMLElement | null): number {
	if (element == null) {
		return 0;
	}
	const rect = element.getBoundingClientRect();
	return rect.top + window.scrollY;
}

// Calculates the total height by which elements below the site header and
// navbars are offset from the top of the page.

export function getNavbarOffsetTop(): number {
	const navbars = document.getElementById('navbars');
	if (navbars == null) {
		return 0;
	}

	const computedStyle = window.getComputedStyle(navbars);
	const header = document.getElementById('site-header');
	const headerAndNavbarOffset =
		computedStyle.position === 'fixed'
			? getTrueOffsetTop(header)
			: navbars.offsetTop;

	return (
		headerAndNavbarOffset +
		parseFloatWithDefault(computedStyle.marginTop, 0)
	);
}

// Calculates the height and width that an element would take if unconstrained,
// making special provision for tables.

export function getRequestedSize(element: HTMLElement): {
	height: number;
	width: number;
} {
	if (element.localName === 'table') {
		const cols = utils.findElements(element, 'col');
		return {
			height: 0,
			width: cols.reduce(
				(sum: number, col: HTMLElement): number =>
					sum + getRequestedSize(col).width,
				0,
			),
		};
	}

	if (element.parentElement == null) {
		return { height: 0, width: 0 };
	}

	element.parentElement.style.display = 'none';
	const computedStyle = window.getComputedStyle(element);
	element.parentElement.style.removeProperty('display');

	return {
		height: parseFloatWithDefault(computedStyle.height, 0),
		width: parseFloatWithDefault(computedStyle.width, 0),
	};
}

// Checks whether the viewport size can accommodate inline display of an
// element based on its CPC XML media role.

export function queryMediaRole(element: HTMLElement): boolean {
	if (element.classList.contains('role-separate')) {
		return false;
	} else if (element.classList.contains('role-large')) {
		return window.matchMedia('(min-width: 992px)').matches;
	} else if (element.classList.contains('role-medium')) {
		return window.matchMedia('(min-width: 768px)').matches;
	} else if (element.classList.contains('role-small')) {
		return window.matchMedia('(min-width: 400px)').matches;
	} else {
		return true;
	}
}

// Calculates the total height of sticky node headers that will be sticky while
// an element is within the viewport.

export function getStuckAncestorsHeight(
	element: HTMLElement & { stuckAncestorsHeight?: number },
): number {
	if (element.stuckAncestorsHeight != null) {
		return element.stuckAncestorsHeight;
	}

	const node = element.parentElement?.closest('article');
	const nodeHeader =
		node != null ? utils.matchingChild(node, '.full-header') : null;
	if (nodeHeader != null) {
		element.stuckAncestorsHeight = nodes.getStuckHeight();
		if (element.stuckAncestorsHeight == null) {
			const inner = utils.matchingChild(nodeHeader, '.inner-header');
			if (inner != null) {
				element.stuckAncestorsHeight = inner.offsetHeight;
			}
		}
	}

	if (element.stuckAncestorsHeight == null) {
		element.stuckAncestorsHeight = 0;
	}
	return element.stuckAncestorsHeight;
}

// Adds the Rails CSRF parameter to form data that will be POSTed.

type FormDataObject = Record<string, string | boolean | number | object>;

export function addCSRFParam(data: FormDataObject | FormData): void {
	const csrfNameEl = document.querySelector('meta[name="csrf-param"]');
	const csrfValueEl = document.querySelector('meta[name="csrf-token"]');
	if (csrfNameEl == null || csrfValueEl == null) {
		return;
	}

	const csrfName = csrfNameEl.getAttribute('content');
	const csrfValue = csrfValueEl.getAttribute('content');
	if (csrfName == null || csrfValue == null) {
		return;
	}

	if (data instanceof FormData) {
		data.append(csrfName, csrfValue);
	} else {
		data[csrfName] = csrfValue;
	}
}

// Converts a plain key-value object to FormData.

export function convertToFormData(data: FormDataObject): FormData {
	const formData = new FormData();
	for (const [field, value] of Object.entries(data)) {
		formData.append(
			field,
			typeof value === 'object'
				? JSON.stringify(value)
				: value.toString(),
		);
	}
	addCSRFParam(formData);
	return formData;
}

// Navigates to a given URL with a POST request, passing the provided data.

export function postTo(href: string, data: FormDataObject = {}): void {
	const form = document.createElement('form');
	form.setAttribute('method', 'post');
	form.setAttribute('enctype', 'application/x-www-form-urlencoded');
	form.setAttribute('action', href);

	addCSRFParam(data);

	for (const [field, value] of Object.entries(data)) {
		const input = document.createElement('input');
		input.setAttribute('type', 'hidden');
		input.setAttribute('name', field);
		input.setAttribute(
			'value',
			typeof value === 'object'
				? JSON.stringify(value)
				: value.toString(),
		);
		form.append(input);
	}

	document.body.appendChild(form);
	form.submit();
}

// Provides a unique ID to be used on a generated element.

export function uniqueID(): string {
	return uniqueId('a');
}

// Returns a promise that resolves after the given number of milliseconds.

export async function delay(ms: number): Promise<void> {
	return new Promise<void>((resolve) => {
		setTimeout(resolve, ms);
	});
}

// Attempts a function repeatedly, with delays between attempts, until the
// function succeeds (returns true) or a limit is hit.

export async function makeAttempts(
	fn: () => boolean | Promise<boolean>,
	limit = -1,
	delayMs = 0,
): Promise<boolean> {
	let attempts = 0;
	while (limit < 0 || attempts < limit) {
		if (await fn()) {
			return true;
		}
		++attempts;
		await delay(delayMs);
	}
	return false;
}

// Equivalent to parseFloat, but falls back to a default value instead of NaN
// on parsing errors.

export function parseFloatWithDefault(
	value: string | null | undefined,
	defaultValue: number,
): number {
	if (value == null) {
		return defaultValue;
	}
	const parsed = parseFloat(value);
	return isNaN(parsed) ? defaultValue : parsed;
}
