import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

application.register(
	'listfilters',
	class Listfilters extends Controller<HTMLFormElement> {
		public static override readonly targets = [
			'filter',
			'yearStart',
			'yearEnd',
		];

		private declare readonly filterTargets: HTMLSelectElement[];

		private declare readonly yearStartTarget: HTMLSelectElement;
		private declare readonly hasYearStartTarget: boolean;

		private declare readonly yearEndTarget: HTMLSelectElement;
		private declare readonly hasYearEndTarget: boolean;

		private oldStartYear: number | null = null;

		public override connect(): void {
			this.updateFilters();
		}

		public override disconnect(): void {
			this.oldStartYear = null;
		}

		protected updateFilters(event?: Event): void {
			// Highlight the filters that are currently set.

			for (const filter of this.filterTargets) {
				filter.classList.toggle(
					'form-control-info',
					filter.value !== '',
				);
				filter.blur();
			}

			// Synchronize the year range filters.

			if (!this.hasYearStartTarget || !this.hasYearEndTarget) {
				return;
			}

			const startYear = parseInt(this.yearStartTarget.value);
			const endYear = parseInt(this.yearEndTarget.value);

			this.yearEndTarget.disabled = isNaN(startYear);

			if (isNaN(startYear)) {
				this.yearEndTarget.value = '';
				return;
			}

			if (event?.target === this.yearStartTarget) {
				// Keep the range one year wide unless expanding the end year.
				if (this.oldStartYear === endYear) {
					this.setEndYear(startYear);
				}
				this.oldStartYear = startYear;
			}

			if (isNaN(endYear) || endYear < startYear) {
				this.setEndYear(startYear);
			}

			for (const option of Array.from(this.yearEndTarget.options)) {
				option.disabled =
					+option.value < startYear || option.value === '';
			}
		}

		protected clear() {
			for (const filter of this.filterTargets) {
				filter.value = '';
				filter.classList.remove('form-control-info');
			}
			this.element.submit();
		}

		private setEndYear(value: number) {
			const valueOption = Array.from(this.yearEndTarget.options).find(
				(option) => +option.value === value,
			);
			this.yearEndTarget.value = (
				valueOption != null ? value : value + 9
			).toString();
			this.yearEndTarget.classList.add('form-control-info');
		}
	},
);
