export function findElements<K extends keyof HTMLElementTagNameMap>(
	ancestor: ParentNode,
	selector: K,
): HTMLElementTagNameMap[K][];
export function findElements<E extends HTMLElement = HTMLElement>(
	ancestor: ParentNode,
	selector: string,
): E[];
export function findElements<E extends HTMLElement = HTMLElement>(
	ancestor: ParentNode,
	selector: string,
): E[] {
	return Array.from(ancestor.querySelectorAll<E>(selector));
}

export function findElementsOrSelf<K extends keyof HTMLElementTagNameMap>(
	ancestor: HTMLElement,
	selector: K,
): HTMLElementTagNameMap[K][];
export function findElementsOrSelf<E extends HTMLElement = HTMLElement>(
	ancestor: HTMLElement,
	selector: string,
): E[];
export function findElementsOrSelf<E extends HTMLElement = HTMLElement>(
	ancestor: HTMLElement,
	selector: string,
): E[] {
	const matching = findElements<E>(ancestor, selector);
	if (ancestor.matches(selector)) {
		matching.unshift(ancestor as E);
	}
	return matching;
}

// Finds the first direct child of the given node that matches the given selector.

export function matchingChild<K extends keyof HTMLElementTagNameMap>(
	parent: ParentNode,
	selector: K,
): HTMLElementTagNameMap[K] | null;
export function matchingChild<E extends HTMLElement = HTMLElement>(
	parent: ParentNode,
	selector: string,
): E | null;
export function matchingChild<E extends HTMLElement = HTMLElement>(
	parent: ParentNode,
	selector: string,
): E | null {
	for (const child of parent.children) {
		if (child.matches(selector)) {
			return child as E;
		}
	}
	return null;
}

// Finds all direct children of the given node that match the given selector.

export function matchingChildren<K extends keyof HTMLElementTagNameMap>(
	parent: ParentNode,
	selector: K,
): HTMLElementTagNameMap[K][];
export function matchingChildren<E extends HTMLElement = HTMLElement>(
	parent: ParentNode,
	selector: string,
): E[];
export function matchingChildren<E extends HTMLElement = HTMLElement>(
	parent: ParentNode,
	selector: string,
): E[] {
	return Array.from(parent.children).filter((child): child is E =>
		child.matches(selector),
	);
}

// Finds all ancestors of the given node that match the given selector.

export function matchingAncestors<K extends keyof HTMLElementTagNameMap>(
	descendant: Node,
	selector: K,
): HTMLElementTagNameMap[K][];
export function matchingAncestors<E extends HTMLElement = HTMLElement>(
	descendant: Node,
	selector: string,
): E[];
export function matchingAncestors<E extends HTMLElement = HTMLElement>(
	descendant: Node,
	selector: string,
): E[] {
	const parents: E[] = [];

	let domNode = descendant;
	while (domNode.parentElement != null) {
		if (domNode.parentElement.matches(selector)) {
			parents.push(domNode.parentElement as E);
		}
		domNode = domNode.parentElement;
	}

	return parents;
}

export function followingSiblings(node: Element): Element[] {
	if (node.parentNode == null) {
		return [];
	}

	const allSiblings = Array.from(node.parentNode.children);
	const childIndex = allSiblings.indexOf(node);
	return allSiblings.slice(childIndex + 1);
}

export function precedingSiblings(node: Element): Element[] {
	if (node.parentNode == null) {
		return [];
	}

	const allSiblings = Array.from(node.parentNode.children);
	const childIndex = allSiblings.indexOf(node);
	return allSiblings.slice(0, childIndex);
}

// Returns the self or sibling at a given offset (positive for following, next
// for preceding or zero for self), while capping to the number of siblings
// available (and thus always returning an element).

export function nthSibling(node: Element, offset = 0): Element {
	if (node.parentNode == null) {
		return node;
	}

	const allSiblings = Array.from(node.parentNode.children);
	const childIndex = allSiblings.indexOf(node);
	const siblingIndex = Math.max(
		0,
		Math.min(allSiblings.length - 1, childIndex + offset),
	);
	return allSiblings[siblingIndex]!;
}

// Formats a pixel value for use in CSS.

export function pxToString(px: number): string {
	return `${px.toString()}px`;
}
