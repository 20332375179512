import { debounce } from 'underscore';
import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';
import * as utils from '../../javascript/utils';
import * as nodes from '../../javascript/nodes';

application.register(
	'subsecnodeactions',
	class Subsecnodeactions extends Controller {
		public static override readonly targets = [
			'header',
			'link',
			'dropdown',
			'menu',
			'history',
		];

		private declare readonly headerTarget: HTMLElement;
		private declare readonly linkTargets: HTMLAnchorElement[];
		private declare readonly historyTarget: HTMLAnchorElement;
		private declare readonly dropdownTarget: HTMLElement;
		private declare readonly menuTarget: HTMLElement;

		public override connect(): void {
			window.addEventListener('cpc:nodes:prepare-page', () => {
				this.preparePage();
			});
		}

		private preparePage(): void {
			const onLeaveDebounced = debounce(() => {
				this.onLeave();
			}, 100);

			const main = document.querySelector<HTMLElement>('main.nodes');
			if (main == null) {
				return;
			}

			main.addEventListener('mouseover', (event: Event) => {
				if (!(event.target instanceof HTMLElement)) {
					return;
				}
				if (event.target.localName === 'section') {
					this.onEnterSubsec(event.target);
				} else if (
					event.target.matches(
						'section > .header, section > p, .subcrumb',
					)
				) {
					this.onEnterSubsecChild(event.target);
				}
			});

			main.addEventListener('mouseout', (event) => {
				if (!(event.target instanceof HTMLElement)) {
					return;
				}
				if (event.target.localName === 'article') {
					onLeaveDebounced();
				}
			});

			const container = document.getElementById('container');
			if (container != null) {
				container.addEventListener('mouseleave', onLeaveDebounced);
			}
		}

		private onEnterSubsec(node: HTMLElement): void {
			// Don't handle the ancestor when a descendant is also being entered.
			if (node.querySelector<HTMLElement>('section:hover') == null) {
				this.setActiveSubsec(node);
			}
		}

		private onEnterSubsecChild(child: HTMLElement): void {
			const node = child.closest('section');
			if (node != null) {
				this.setActiveSubsec(node);
			}
		}

		private setActiveSubsec(node: HTMLElement): void {
			// The actions don't make sense without a citation form, etc.
			const cite = node.dataset['shortCite'] ?? node.dataset['cite'];
			if (cite == null || node.classList.contains('type-none')) {
				return;
			}

			// <section>s inside previews are transitory.
			// <section>s inside <blockquote>s are not CPC XML subsections.
			if (node.closest('.preview, blockquote') != null) {
				return;
			}

			// Don't redundantly set an already-active subsec.
			if (node.classList.contains('active')) {
				return;
			}

			for (const subsec of nodes.getSubsecNodes()) {
				subsec.classList.remove('active');
			}
			node.classList.add('active');

			// Don't show the action menu for hash-disambiguated nodes.
			if (node.id.includes('__')) {
				this.element.classList.add('invisible');
				return;
			}

			this.headerTarget.innerText = cite;

			for (const link of this.linkTargets) {
				const template = link.dataset['template'] ?? '';
				link.href = template.replace(/\bXyzzy\b/g, node.id);
			}

			this.historyTarget.classList.toggle(
				'disabled',
				!node.classList.contains('has-history'),
			);

			const firstParagraph = utils.matchingChild(node, 'p');
			this.dropdownTarget.style.height =
				firstParagraph != null
					? utils.pxToString(firstParagraph.offsetHeight)
					: 'auto';

			this.element.classList.remove('invisible');
			node.prepend(this.element);
		}

		private onLeave(): void {
			if (
				this.element.parentElement != null &&
				this.element.parentElement.matches('section:hover')
			) {
				return;
			}

			for (const subsec of nodes.getSubsecNodes()) {
				subsec.classList.remove('active');
			}

			this.element.classList.add('invisible');

			if (this.dropdownTarget.classList.contains('open')) {
				jQuery(this.menuTarget).dropdown('toggle');
			}
		}
	},
);
