import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

import * as utils from '../../javascript/utils';
import * as nodes from '../../javascript/nodes';
import Dynamodal from '../../javascript/dynamodal';
import * as site from '../../javascript/site';

application.register(
	'levelnodes',
	class Levelnodes extends Controller {
		public static override readonly targets = ['header', 'actions'];

		private declare readonly headerTargets: HTMLElement[];
		private declare readonly actionsTarget: HTMLElement;
		private declare readonly hasActionsTarget: boolean;

		public override connect(): void {
			window.addEventListener('cpc:nodes:prepare-page', () => {
				this.preparePage();
			});
		}

		protected openHistory(event: Event): void {
			if (!(event.target instanceof HTMLElement)) {
				return;
			}

			const historyLink =
				event.target.closest<HTMLElement>('.has-history')?.dataset[
					'history'
				];
			if (historyLink != null) {
				void Dynamodal.spawn(historyLink);
			}
		}

		private preparePage(): void {
			if (!this.hasActionsTarget) {
				return;
			}
			const firstActions = this.actionsTarget;
			for (const header of this.headerTargets) {
				const actions = firstActions.cloneNode(true) as HTMLElement;
				if (
					header.classList.contains('full-header') &&
					header.querySelector('.levelnodeactions') == null
				) {
					const inner =
						header.querySelector<HTMLElement>('.inner-header');
					if (inner != null) {
						inner.appendChild(actions);
					}

					if (nodes.getStuckHeight() == null) {
						header.classList.add('stuck', 'measuring');
						nodes.setStuckHeight(header.offsetHeight);
						header.classList.remove('stuck', 'measuring');
					}

					header.style.top = utils.pxToString(
						site.getTopStuckOffset(),
					);
					header.style.minHeight = utils.pxToString(
						header.offsetHeight,
					);

					const name = header.querySelector<HTMLElement>('.name');
					if (name != null) {
						name.setAttribute('title', utils.getCleanText(name));
					}
				} else {
					header.appendChild(actions);
				}
			}

			const main = document.querySelector<HTMLElement>('main.nodes');
			if (main == null) {
				return;
			}
			if (!main.classList.contains('node-headers')) {
				main.classList.add('node-headers');
			}

			if (firstActions.parentNode != null) {
				firstActions.parentNode.removeChild(firstActions);
			}
		}
	},
);
