import * as utils from './utils';
import { getControllerForElement } from './application';
import * as site from './site';
import type Floattable from '../components/floattable_component/floattable_component';

// Animates a change in scrollTop on an element (or, by default, the window)
// with a velocity suitable to the distance travelled.

export function animate(
	target: number | 'top' | 'bottom',
	elementOrWindow: HTMLElement | Window = window,
	callback: () => void = utils.noop,
): number {
	let element: HTMLElement;
	if (elementOrWindow instanceof Window) {
		element = elementOrWindow.document.documentElement;
	} else {
		element = elementOrWindow;
	}

	let scrollTop: number;
	if (target === 'top') {
		scrollTop = 0;
	} else if (target === 'bottom') {
		scrollTop = element.scrollHeight - element.offsetHeight;
	} else {
		scrollTop = target;
	}

	const previousScrollTop = jQuery(elementOrWindow).scrollTop() ?? 0;
	const distance = Math.abs(previousScrollTop - scrollTop);
	const downward = scrollTop > previousScrollTop;

	const MIN_DURATION = 300;
	const MAX_DURATION = 1200;
	const DISTANCE_PER_MS = 3;
	const MAX_DISTANCE = MAX_DURATION * DISTANCE_PER_MS * 1.25;

	const duration = Math.max(
		MIN_DURATION,
		Math.min(MAX_DURATION, distance / DISTANCE_PER_MS),
	);

	const $element = jQuery(element);
	$element.stop(true);

	if (duration < MAX_DURATION) {
		// For a reasonable distance, scroll smoothly from start to end.
		$element.animate(
			{ scrollTop, scrollLeft: 0 },
			duration,
			'swing',
			callback,
		);
	} else {
		// For an unreasonable distance, make a jump in the middle. Going the
		// full max distance in each half of the max duration creates an
		// impression of greater speed around the jump.
		const partialDistance =
			Math.min(MAX_DISTANCE, distance / 2) * (downward ? 1 : -1);
		$element.animate(
			{
				scrollTop: previousScrollTop + partialDistance,
				scrollLeft: 0,
			},
			MAX_DURATION / 2,
			'swingIn',
		);
		$element.animate(
			{
				scrollTop: scrollTop - partialDistance,
				scrollLeft: 0,
			},
			0,
			'linear',
		);
		$element.animate(
			{ scrollTop, scrollLeft: 0 },
			MAX_DURATION / 2,
			'swingOut',
			callback,
		);
	}

	return duration;
}

// Returns whether a change in scrollTop on an element (or, by default, the
// window) is currently being animated.

export function isAnimated(element?: HTMLElement): boolean {
	return (element != null ? jQuery(element) : jQuery('html, body')).is(
		':animated',
	);
}

// Scrolls to make an element visible in the viewport or a given container,
// adjusting for sticky elements and optionally animating.

export interface IScrollIntoViewOptions {
	animate: boolean;
	container: HTMLElement | Window;
	minMargin: number;
	noDelay: boolean;
	waitForLoad: boolean;
}

export function intoView(
	element: HTMLElement,
	partialOptions: Partial<IScrollIntoViewOptions> = {},
): void {
	const options = {
		animate: false,
		container: window,
		minMargin: 5,
		noDelay: false,
		waitForLoad: true,
		...partialOptions,
	} satisfies IScrollIntoViewOptions;

	if (options.waitForLoad && document.readyState !== 'complete') {
		window.addEventListener(
			'load',
			() => {
				intoView(element, { ...options, waitForLoad: false });
			},
			{ once: true },
		);
		return;
	}

	const offsetTop =
		options.container === window
			? element.getBoundingClientRect().top +
				document.documentElement.scrollTop
			: element.offsetTop;

	let scrollTop =
		offsetTop -
		Math.max(
			options.minMargin,
			utils.parseFloatWithDefault(
				window.getComputedStyle(element).marginTop,
				0,
			),
		);

	const tableAncestor = element.closest('table');
	if (tableAncestor != null) {
		const floattableController = getControllerForElement<Floattable>(
			tableAncestor,
			'floattable',
		);
		if (floattableController != null) {
			scrollTop -= floattableController.headerHeight;
		}
	}

	if (options.container === window) {
		scrollTop -= site.getTopStuckOffset();
		scrollTop -= utils.getStuckAncestorsHeight(element);
	}

	if (options.animate) {
		animate(scrollTop, options.container, () => {
			// True up the scroll position at the end, since interactions between
			// transitions and the scroll animation can throw things off.
			options.animate = false;
			options.noDelay = true;
		});
	} else {
		setTimeout(
			() => {
				jQuery(options.container).scrollTop(scrollTop).scrollLeft(0);
			},
			options.noDelay ? 0 : 100,
		);
	}
}
