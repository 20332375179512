import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

application.register(
	'liftedlink',
	class Liftedlink extends Controller {
		public static override readonly targets = ['source', 'exempt'];

		private declare readonly sourceTarget: HTMLElement;
		private declare readonly hasSourceTarget: boolean;
		private declare readonly exemptTargets: HTMLElement[];

		private readonly clickListener: EventListener = (event) => {
			this.click(event);
		};

		public override connect(): void {
			if (!this.hasSourceTarget) {
				return;
			}

			this.element.classList.add('liftedlink--attached');
			this.sourceTarget.classList.add('liftedlink-source--attached');
			for (const exempt of this.exemptTargets) {
				exempt.classList.add('liftedlink-exempt--attached');
			}

			this.element.addEventListener('click', this.clickListener);
		}

		public override disconnect(): void {
			this.element.removeEventListener('click', this.clickListener);
		}

		public click(event: Event): void {
			if (!(event.target instanceof HTMLElement)) {
				return;
			}

			// Ignore clicks on exempt elements within the lifted element.
			if (event.target.closest('.liftedlink-exempt--attached') != null) {
				return;
			}

			// Ignore clicks on the original lifted link, which will work anyway.
			if (!this.hasSourceTarget || event.target === this.sourceTarget) {
				return;
			}

			event.preventDefault();
			this.sourceTarget.click();
		}
	},
);
