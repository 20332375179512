import { Controller } from '@hotwired/stimulus';
import application from '../../javascript/application';

import ClipboardJS from 'clipboard';

import * as utils from '../../javascript/utils';
import * as analytics from '../../javascript/analytics';
import * as selections from '../../javascript/selections';

interface IShareAction {
	link: HTMLElement;
	href: string;
	method: string;
	format?: string;

	isError: boolean;

	deferTabShow?: Promise<void>;
	tryAgainTimeout?: number;

	xhr?: XMLHttpRequest;
	blob?: Blob;
	blobURL?: string;

	filename?: string;
	iframe?: HTMLIFrameElement;
	manualPrint?: boolean;
}

application.register(
	'sharemodal',
	class Sharemodal extends Controller<HTMLElement> {
		public static override readonly targets = [
			'header',
			'mainTab',
			'url',
			'copy',
			'completepane',
			'chosenicon',
			'chosentext',
			'tryagain',
		];
		public static override readonly values = {
			url: String,
			xml: Boolean,
			selections: Boolean,
		};

		private declare readonly tryagainTargets: HTMLElement[];
		private declare readonly choseniconTargets: HTMLElement[];
		private declare readonly chosentextTargets: HTMLElement[];
		private declare readonly completepaneTarget: HTMLElement;

		private declare readonly urlTarget: HTMLInputElement;
		private declare readonly copyTarget: HTMLElement;
		private declare readonly hasUrlTarget: boolean;
		private declare readonly hasCopyTarget: boolean;

		private declare readonly mainTabTargets: HTMLElement[];

		private declare urlValue: string;
		private declare xmlValue: boolean;
		private declare selectionsValue: boolean;

		private clipboard: ClipboardJS | null = null;
		private asyncActions: IShareAction[] = [];
		private actionLink: HTMLElement | null = null;

		public override connect(): void {
			this.prepareCommon(this.element);
			this.element.addJQEventListener('hide.bs.modal', () => {
				this.asyncAbort();
			});
		}

		public override disconnect() {
			if (this.clipboard != null) {
				this.clipboard.destroy();
			}
			this.clipboard = null;

			this.asyncAbort();

			this.actionLink = null;
		}

		protected editSelections(): void {
			jQuery(this.element).modal('hide');
			selections.show();
		}

		protected urlSelect(event: Event): void {
			if (event.target instanceof HTMLInputElement) {
				event.target.select();
			}
		}

		protected retryAction(event: Event) {
			event.preventDefault();
			if (this.actionLink != null) {
				this.actionLink.click();
			}
		}

		private prepareCommon(share: HTMLElement): void {
			if (!ClipboardJS.isSupported()) {
				return;
			}
			if (!this.hasUrlTarget || !this.hasCopyTarget) {
				return;
			}

			const urlInput = this.urlTarget;
			const copyButton = this.copyTarget;
			copyButton.classList.remove('disabled');

			if (this.clipboard != null) {
				this.clipboard.destroy();
			}
			this.clipboard = new ClipboardJS('.share-copy', {
				container: this.element,
				text: () => urlInput.value,
			});

			this.clipboard.on('success', () => {
				this.fixModalScrollAfterClipboard();
				const copySuccess = share.querySelector<HTMLElement>(
					'.share-copy-success',
				);
				if (copySuccess != null) {
					copySuccess.classList.remove('d-none');
				}
			});

			this.clipboard.on('error', () => {
				this.fixModalScrollAfterClipboard();
				const copySuccess = share.querySelector<HTMLElement>(
					'.share-copy-failure',
				);
				if (copySuccess != null) {
					copySuccess.classList.remove('d-none');
				}
			});
		}

		private fixModalScrollAfterClipboard(): void {
			this.element.scrollTop = 0;
			const textarea = utils.matchingChild(this.element, 'textarea');
			if (textarea != null) {
				textarea.style.display = 'none';
			}
		}

		protected onClickAction(event: Event): void {
			if (event.target instanceof HTMLElement) {
				this.startAction(event.target, event);
			}
		}

		private startAction(link: HTMLElement, event?: Event): void {
			this.actionLink = link;
			this.displayStartedAction(link);

			const href = link.getAttribute('href');
			const method = link.dataset['shareMethod'];
			const format = link.dataset['shareFormat'] ?? 'original';
			if (href == null || method == null) {
				return;
			}

			const action: IShareAction = {
				link,
				href,
				method,
				format,
				isError: false,
			};

			analytics.recordShareEvent(
				method,
				format,
				this.selectionsValue ? 'selections' : this.urlValue,
			);

			if (action.method === 'save') {
				const match = /\/([^/]+)\/export(\.\w+)\?download=true$/.exec(
					action.href,
				);
				if (match != null) {
					action.filename = `${match[1]!}${match[2]!}`;
				}
			}

			if (action.method === 'save' || action.method === 'print') {
				if (event != null) {
					event.preventDefault();
				}
				this.asyncAbort();
				void this.asyncStart(action);
			}
		}

		private displayStartedAction(link: HTMLElement): void {
			const icon = utils.matchingChild(link, '.glyphicons, .social');
			for (const chosenIcon of this.choseniconTargets) {
				chosenIcon.className = icon?.className ?? '';
				chosenIcon.classList.add('chosen-icon');
			}

			const text = utils.matchingChild(link, '.text');
			for (const chosenText of this.chosentextTargets) {
				chosenText.innerText = text?.innerText ?? '';
			}

			for (const tryAgain of this.tryagainTargets) {
				tryAgain.classList.add('d-none');
			}
		}

		private async asyncStart(action: IShareAction): Promise<void> {
			this.asyncActions.push(action);
			// NOTE: We can't use datasets on the tabs b/c it confuses Bootstrap.
			const progressTab = document.getElementById('share-progress-tab');
			if (progressTab != null) {
				jQuery(progressTab).tab('show');
				action.deferTabShow = new Promise((resolve) => {
					progressTab.addJQEventListener(
						'shown.bs.tab',
						() => {
							resolve();
						},
						{ once: true },
					);
				});
			}

			for (const tryAgain of this.tryagainTargets) {
				tryAgain.classList.add('d-none');
				action.tryAgainTimeout = window.setTimeout(() => {
					tryAgain.classList.remove('d-none');
				}, 60 * 1000); // 60 seconds
			}

			let response: Response;
			try {
				let body: FormData | undefined;
				if (this.xmlValue) {
					body = utils.convertToFormData(
						this.selectionsValue
							? { selections: selections.get() }
							: {},
					);
				}

				response = await fetch(action.href, {
					method: this.xmlValue ? 'POST' : 'GET',
					body,
				});
				if (!response.ok) {
					throw new Error(response.statusText);
				}

				action.blob = await response.blob();
				action.blobURL = URL.createObjectURL(action.blob);
			} catch (error) {
				action.isError = true;
				await this.showResult(action);
				return;
			} finally {
				if (action.tryAgainTimeout != null) {
					clearTimeout(action.tryAgainTimeout);
				}
			}

			const fileLocation = new URL(
				response.url !== '' ? response.url : action.href,
				location.href,
			);
			const contentDisposition =
				response.headers.get('Content-Disposition') ??
				// For S3 redirects, look in the decoded query string to get
				// around CORS hiding the actual Content-Disposition here.
				decodeURIComponent(fileLocation.search);

			// Use filename from content disposition, falling back to URL.
			const asciiMatch = /filename[^;=\n]*=['"]?([^;\n'"&]+)/.exec(
				contentDisposition,
			);
			const utf8Match = /filename\*=utf-8''([^;\n&]+)/.exec(
				contentDisposition,
			);
			action.filename =
				utf8Match != null
					? decodeURIComponent(utf8Match[1]!)
					: (asciiMatch?.[1] ??
						action.filename ??
						fileLocation.pathname.replace(/^.*\//, ''));

			if (action.method === 'print') {
				await this.printBlob(action);
			} else {
				await this.navigateToBlob(action);
			}
		}

		private async showResult(action: IShareAction): Promise<void> {
			if (action.deferTabShow != null) {
				await action.deferTabShow;
			}

			if (action.isError) {
				jQuery('#share-error-tab').tab('show');
			} else {
				for (const tryAgain of this.tryagainTargets) {
					tryAgain.classList.remove('d-none');
				}
				jQuery('#share-complete-tab').tab('show');
				const manualPrint =
					this.completepaneTarget.querySelector('.manual-print');
				if (manualPrint != null) {
					manualPrint.classList.toggle(
						'd-none',
						!(action.manualPrint ?? false),
					);
				}
			}
		}

		private async printBlob(action: IShareAction): Promise<void> {
			if (action.blobURL == null) {
				throw new Error('Cannot print file without blob URL.');
			}
			return new Promise((resolve, reject: (error: unknown) => void) => {
				action.iframe = document.createElement('iframe');
				action.iframe.classList.add('share-print-iframe');
				action.iframe.addEventListener(
					'load',
					() => {
						// No further action is required to raise a Print dialog, since the
						// print trigger is baked into each PDF that is returned.

						// Firefox's PDF.js takes a while to bring up the Print dialog.
						// A small delay here keeps the progress bar up until it's ready.
						setTimeout(() => {
							this.showResult(action).then(resolve).catch(reject);
						}, 500);
					},
					{ once: true },
				);
				action.iframe.src = action.blobURL!;
				document.body.appendChild(action.iframe);
			});
		}

		private async navigateToBlob(action: IShareAction): Promise<void> {
			if (action.blobURL == null) {
				throw new Error('Cannot navigate to a file without blob URL.');
			}

			const a = document.createElement('a');
			a.href = action.blobURL;
			if (action.method === 'save') {
				a.download = action.filename ?? '';
			} else {
				a.target = '_blank';
				a.rel = 'noopener noreferrer';
			}
			a.style.display = 'none';

			document.body.appendChild(a);
			a.click();
			a.remove();

			await this.showResult(action);
		}

		private asyncAbort(): void {
			while (this.asyncActions.length > 0) {
				const action = this.asyncActions.shift();
				if (action == null) {
					continue;
				}
				if (action.xhr != null) {
					action.xhr.abort();
				}
				if (action.tryAgainTimeout != null) {
					clearTimeout(action.tryAgainTimeout);
				}
				if (action.iframe != null) {
					action.iframe.remove();
				}
				if (action.blobURL != null) {
					URL.revokeObjectURL(action.blobURL);
				}
			}
		}
	},
);
