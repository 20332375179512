import { Application, Controller } from '@hotwired/stimulus';

const application = Application.start();

export default application;

export function getControllerForElement<T extends Controller>(
	element: T['element'],
	identifier: string,
): T | null {
	return application.getControllerForElementAndIdentifier(
		element,
		identifier,
	) as T | null;
}

export function addControllerToElement(
	element: HTMLElement,
	identifier: string,
): void {
	const controllers = new Set(
		element.dataset['controller']?.split(' ') ?? [],
	);
	controllers.add(identifier);
	element.dataset['controller'] = Array.from(controllers).join(' ');
}

export function removeControllerFromElement(
	element: HTMLElement,
	identifier: string,
): void {
	const controllers = new Set(
		element.dataset['controller']?.split(' ') ?? [],
	);
	controllers.delete(identifier);
	element.dataset['controller'] = Array.from(controllers).join(' ');
}
