import { Controller } from '@hotwired/stimulus';

import application from '../../javascript/application';

import * as utils from '../../javascript/utils';
import * as site from '../../javascript/site';
import Autosubmit from '../autosubmit_component/autosubmit_component';

application.register(
	'searchresultlist',
	class Searchresultlist extends Controller {
		public static override readonly targets = [
			'form',
			'noneButton',
			'allScope',
			'noneScope',
			'nodeScope',
			'scope',
			'scopeType',
			'sticky',
		];

		private declare readonly formTarget: HTMLFormElement;
		private declare readonly noneButtonTarget: HTMLButtonElement;
		private declare readonly allScopeTarget: HTMLInputElement;
		private declare readonly hasAllScopeTarget: boolean;
		private declare readonly noneScopeTarget: HTMLInputElement;
		private declare readonly hasNoneScopeTarget: boolean;
		private declare readonly nodeScopeTarget: HTMLInputElement;
		private declare readonly hasNodeScopeTarget: boolean;
		private declare readonly scopeTargets: HTMLInputElement[];
		private declare readonly scopeTypeTargets: HTMLElement[];
		private declare readonly stickyTargets: HTMLElement[];

		public override connect(): void {
			window.addEventListener('cpc:prepare-page', () => {
				this.preparePage();
			});
			this.element.classList.add('searchresultlist--attached');
		}

		public override disconnect(): void {
			this.element.classList.remove('searchresultlist--attached');
		}

		private preparePage(): void {
			for (const sticky of this.stickyTargets) {
				sticky.style.top = utils.pxToString(site.getTopStuckOffset());
				sticky.style.position = 'sticky';
			}

			this.updateScope();
		}

		protected clearScopes(event: Event): void {
			event.preventDefault();
			this.noneScopeTarget.click();
		}

		protected updateScopesOfType(event: Event): void {
			const control = event.target;
			if (!(control instanceof HTMLInputElement)) {
				return;
			}

			const scopeType = control.closest('fieldset');
			if (scopeType == null) {
				return;
			}

			const scopesOfType = utils.findElements<HTMLInputElement>(
				scopeType,
				'input.searchresultlist-scope-control',
			);
			for (const scope of scopesOfType) {
				scope.checked = control.checked;
			}

			this.updateScope(event);
			Autosubmit.tryToSubmit(this.formTarget);
		}

		protected updateScope(event?: Event): void {
			const changedScope =
				event?.target instanceof HTMLInputElement ? event.target : null;

			// Reconcile the special and regular scopes with each other.
			this.applyScope(changedScope);

			// Update the scope type checkboxes.
			this.updateScopeTypes();

			// Update the "None" button.
			if (this.hasNoneScopeTarget) {
				this.noneButtonTarget.disabled = this.noneScopeTarget.checked;
			}
		}

		private applyScope(changedScope: HTMLInputElement | null): void {
			const otherScopes = this.scopeTargets.filter(
				(input) => input.type === 'checkbox',
			);

			if (
				(this.hasNoneScopeTarget &&
					changedScope === this.noneScopeTarget) ||
				(this.hasNodeScopeTarget &&
					changedScope === this.nodeScopeTarget)
			) {
				if (changedScope.checked) {
					for (const input of otherScopes) {
						input.checked = false;
					}
				}
				// The radio group will take care of the other two.
			} else if (
				this.hasAllScopeTarget &&
				changedScope === this.allScopeTarget
			) {
				if (changedScope.checked) {
					for (const input of otherScopes) {
						input.checked = true;
					}
				}
				// The radio group will take care of the other two.
			} else {
				//
				this.updateSpecialScopes(changedScope, otherScopes);
			}
		}

		private updateSpecialScopes(
			changedScope: HTMLInputElement | null,
			otherScopes: readonly HTMLInputElement[],
		): void {
			if (!this.hasNoneScopeTarget || !this.hasAllScopeTarget) {
				return;
			}

			const anyOtherChecked = otherScopes.some((input) => input.checked);
			const allOtherChecked = otherScopes.every((input) => input.checked);
			const nodeChecked =
				this.hasNodeScopeTarget && this.nodeScopeTarget.checked;

			this.noneScopeTarget.checked = !nodeChecked && !anyOtherChecked;
			this.allScopeTarget.checked = allOtherChecked;

			if (
				(changedScope != null || anyOtherChecked) &&
				this.hasNodeScopeTarget
			) {
				this.nodeScopeTarget.checked = false;
			}
		}

		private updateScopeTypes(): void {
			for (const scopeType of this.scopeTypeTargets) {
				const control = scopeType.querySelector<HTMLInputElement>(
					'input.searchresultlist-scopetype-control',
				);
				if (control == null) {
					continue;
				}

				const scopesOfType = utils.findElements<HTMLInputElement>(
					scopeType,
					'input.searchresultlist-scope-control',
				);
				control.checked = scopesOfType.every((input) => input.checked);
			}
		}

		protected onSubmit(event: SubmitEvent): void {
			// The suggestion to search an entire product instead of a node
			// needs to change scope and add doc[]. The latter is baked into
			// the button, but the former must be handled here.
			if (
				event.submitter instanceof HTMLButtonElement &&
				event.submitter.classList.contains(
					'searchresultlist-suggestion',
				) &&
				event.submitter.name === 'doc[]'
			) {
				if (this.hasNodeScopeTarget) {
					this.nodeScopeTarget.checked = false;
				}
			}

			// Cancel the submission if it was automatic and no scope is selected.
			if (
				event.submitter == null &&
				this.hasNoneScopeTarget &&
				this.noneScopeTarget.checked
			) {
				event.preventDefault();
			}
		}

		// This will be called on manual or automatic submission. It should
		// handle any cleanup that does not need to know the submitter.
		protected onFormData(event: FormDataEvent): void {
			// Remove the scope types, which are for input convenience only.
			event.formData.delete('type[]');

			// Only submit the last mode and scope given. Multiples can occur
			// due to the way suggestion links are built.
			event.formData.set(
				'mode',
				event.formData.getAll('mode').at(-1) ?? 'smart',
			);
			const scope = event.formData.getAll('scope').at(-1) ?? 'multiple';
			if (scope === 'multiple') {
				event.formData.delete('scope');
			} else {
				event.formData.set('scope', scope);
			}

			// If a scope other than the default "multiple" is chosen, don't
			// submit the individual scope selections because they don't apply.
			if (
				event.formData.has('scope') &&
				event.formData.get('scope') !== 'multiple'
			) {
				event.formData.delete('doc[]');
				event.formData.delete('leg[]');
				event.formData.delete('mtg[]');
			}
		}
	},
);
